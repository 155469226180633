import { IOption } from '../../../../shared/models/option/Option.interface';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { isComponentLoading } from '../../../../shared/constants/store.constants';
import { ILoading } from '../../../../shared/models/other/store/Loading.interface';
import { ELoadingContent } from '../../../../shared/models/other/store/Loading.enum';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input()
  options: IOption[];

  @Input()
  option: IOption;

  @Input()
  loading: ILoading;

  @Output()
  optionSelected$: EventEmitter<IOption> = new EventEmitter();

  constructor(private authService: MsalService, private router: Router) { }

  ngOnInit() {
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.subtypeSelected);
  }

  getOptionIcon(option: IOption): string {
    if (this.isIconActive(option)) {
      return option.iconActive;
    } else {
      return option.icon;
    }
  }

  isIconActive(option: IOption): boolean {
    if (this.option) {
      if (this.option.codOption === option.codOption) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onLogout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  navigateToOption(option: IOption): void {
    this.optionSelected$.emit(option);
  }
}
