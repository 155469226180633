import { IProperty } from '../../../../../../shared/models/other/property/Property.interface';
import { TEMPLATE_TEXT } from './../../../../../../shared/constants/text.constants';
import { IFieldStage } from '../../../../../../shared/models/field/FieldStage.interface';
import { IField } from '../../../../../../shared/models/field/Field.interface';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ILoading } from '../../../../../../shared/models/other/store/Loading.interface';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { isComponentLoading } from '../../../../../../shared/constants/store.constants';
import { ITemplate } from '../../../../../../shared/models/template/Template.interface';
import { Component, OnInit, Input, OnChanges, ViewEncapsulation, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ALPHA_NO_SPACE_REGEX, PARAGRAPH_UTF8_REGEX, NUMBERS_REGEX } from '../../../../../../../app/shared/constants/regex.constants';
import { noop } from 'rxjs';
import { convertMessageToExample, nameToNumberAsVariable } from '../../../../../../../app/shared/pipes/template-sms.pipe';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateFormComponent implements OnInit, OnChanges {

  @Input()
  template: ITemplate;

  @Input()
  loading: ILoading;

  @Input()
  fields: IField[];

  @Input()
  fieldsTemplate: IFieldStage[];

  @Output()
  templateUpdated$: EventEmitter<ITemplate> = new EventEmitter();

  @Output()
  emailFieldsUpdated$: EventEmitter<IFieldStage[]> = new EventEmitter();

  @Output()
  templateNotify$: EventEmitter<ITemplate> = new EventEmitter();

  @ViewChild('optionsButton', { static: false })
  optionsButton: ElementRef;

  @ViewChild('pageTitle', { static: false })
  titleTag: ElementRef;

  smsTextChild: string;

  smsFormGroup: FormGroup;
  emailFormGroup: FormGroup;

  submitedSms: boolean;
  submitedEmail: boolean;
  smsAlreadyConverted: boolean;

  property: IProperty = TEMPLATE_TEXT;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.initVariables();
  }

  ngOnChanges() {
    if (this.templateExists()) {
      this.initForms();
      this.smsTextOnChange();
    }
  }

  smsTextOnChange() {
    if (this.template.smsMessage.match(/\[[0-9]\]/g)) {
      this.template.smsMessage = nameToNumberAsVariable(this.template.smsMessage, this.fields, true);
      this.smsFormGroup.get('smsText').setValue(this.template.smsMessage);
    }
    this.smsTextChild = convertMessageToExample(this.smsFormGroup.get('smsText').value, this.fields);
  }

  initVariables(): void {
    this.submitedSms = false;
    this.submitedEmail = false;
    this.smsAlreadyConverted = false;
  }

  initForms(): void {
    const maxlengthSMSText = 300;
    const minlengthEmailId = 1;
    const maxlengthEmailId = 99999999;
    const maxlengthEmailSender = 55;

    this.smsFormGroup = this.formBuilder.group({
      smsText: new FormControl(this.template.smsMessage, [
        Validators.required,
        Validators.maxLength(maxlengthSMSText),
        Validators.pattern(PARAGRAPH_UTF8_REGEX),
      ])
    });

    this.emailFormGroup = this.formBuilder.group({
      emailId: new FormControl(this.template.emailId, [
        Validators.required,
        Validators.min(minlengthEmailId),
        Validators.max(maxlengthEmailId),
        Validators.pattern(NUMBERS_REGEX)
      ]),
      emailSender: new FormControl(this.template.emailSender, [
        Validators.required,
        Validators.maxLength(maxlengthEmailSender),
        Validators.pattern(ALPHA_NO_SPACE_REGEX)
      ]),
      emailFields: new FormControl(this.fieldsTemplate.map(e => e.codFieldSubtype), [
        Validators.required
      ])
    });

    this.smsFormGroup.disable();
    this.emailFormGroup.disable();
  }

  onEdit(type: string) {
    if (type === 'sms') {
      if (this.smsFormGroup.disabled) {
        this.smsFormGroup.enable();
        this.optionsButton.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else {
        this.smsFormGroup.disable();
        this.titleTag.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
      this.emailFormGroup.disable();
    } else {
      if (this.emailFormGroup.disabled) {
        this.optionsButton.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        this.emailFormGroup.enable();
      } else {
        this.titleTag.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        this.emailFormGroup.disable();
      }
      this.smsFormGroup.disable();
    }
  }

  onCancel() {
    if (this.smsFormGroup.enabled) {
      this.onEdit('sms');
    } else if (this.emailFormGroup.enabled) {
      this.onEdit('email');
    } else {
      noop();
    }
  }

  onSubmit() {
    const statusSmsForm = this.smsFormGroup.status;
    if (statusSmsForm === 'DISABLED') {
      this.submitedEmail = true;
      this.submitedSms = false;
    } else {
      this.submitedEmail = false;
      this.submitedSms = true;
    }
    this.smsFormGroup.enable();
    this.emailFormGroup.enable();
    const emailFields = [];
    if (this.smsFormGroup.valid) {
      this.template.smsMessage = nameToNumberAsVariable(this.smsFormGroup.get('smsText').value, this.fields);
      this.smsAlreadyConverted = false;
    } else if (!this.templateExists()) {
      this.smsFormGroup.reset();
    }
    if (this.emailFormGroup.valid) {
      this.emailFormGroup.get('emailFields').value.forEach(e => {
        emailFields.push(
          {
            codStageFieldSubtype: null,
            codStage: this.template.codStage,
            codFieldSubtype: e
          }
        );
      });
      this.template.emailSender = this.emailFormGroup.get('emailSender').value;
      this.template.emailId = this.emailFormGroup.get('emailId').value;
    } else if (!this.templateExists()) {
      this.smsFormGroup.reset();
    }

    if (statusSmsForm !== 'DISABLED') {
      this.smsFormGroup.enable();
      this.emailFormGroup.disable();
    } else {
      this.smsFormGroup.disable();
      this.emailFormGroup.enable();
    }

    if (this.smsFormGroup.enabled && this.smsFormGroup.valid) {
      this.updateTemplate(emailFields);
      this.onEdit('sms');
    } else if (this.emailFormGroup.enabled && this.emailFormGroup.valid) {
      this.updateTemplate(emailFields);
      this.onEdit('email');
    } else {
      noop();
    }
  }

  updateTemplate(emailFields = []) {
    this.templateUpdated$.emit(this.template);
    this.emailFieldsUpdated$.emit(emailFields);
  }


  templateExists(): boolean {
    return this.template ? true : false;
  }

  isReadOnly(type: string): boolean {
    if (type === 'sms') {
      return (this.templateExists()) ? this.smsFormGroup.disabled : true;
    } else {
      return (this.templateExists()) ? this.emailFormGroup.disabled : true;
    }
  }

  getTemplateProperty(property: string): string | boolean | number {
    if (property === 'name') {
      if (this.templateExists()) {
        return this.template.stageName;
      } else {
        return 'Cargando plantilla...';
      }
    } else if (property === 'smsEnabled') {
      if (this.templateExists()) {
        return this.template.emailEnabled;
      } else {
        return false;
      }
    } else if (property === 'smsMessage') {
      if (this.templateExists()) {
        return this.template.smsMessage;
      } else {
        return '';
      }
    } else {
      return false;
    }
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.templateSelected);
  }
}
