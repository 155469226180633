import { capitalizeString } from '../../../../../../shared/constants/formats.constants';
import { Component, OnInit, Input } from '@angular/core';
import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { ILoading } from '../../../../../../shared/models/other/store/Loading.interface';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { isComponentLoading } from '../../../../../../shared/constants/store.constants';
import { noop } from 'rxjs';

@Component({
  selector: 'app-stages-order-preview',
  templateUrl: './stages-order-preview.component.html',
  styleUrls: ['./stages-order-preview.component.scss']
})
export class StagesOrderPreviewComponent implements OnInit {

  @Input()
  stages: IStage[];

  @Input()
  loading: ILoading;
  stagesPre: IStage[];

  constructor() { }

  ngOnInit() {
    if (this.stages) {
      this.stages.sort((a, b) => (a.position > b.position) ? 1 : (b.position > a.position) ? -1 : 0);
      this.stagesPre = this.stages.filter(st => st.expiry);
    } else {
      noop();
    }
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.stageList);
  }

  capitalizeString(name: string): string {
    return capitalizeString(name);
  }

  stageIsLast(stage: IStage): boolean {
    return (this.stagesPre && stage) ? stage.position === this.stagesPre[this.stagesPre.length - 1].position : false;
  }

  getStagesByExpire(expiry: boolean) {
    this.stagesPre = this.stages?.filter(sta => sta.expiry === expiry);
    return this.stagesPre;
  }

}
