import { GetOptionSuccess } from './../actions/option.actions';
import { ofRoute } from '../../../shared/pipes/router-store.pipe';
import { OptionService } from '../../../core/services/option.service';
import { GetOptions, EOptionActions, GetOptionsSuccess } from '../../../core/store/actions/option.actions';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { IResponse } from '../../../shared/models/other/http/Response.interface';
import { GetLoadingSuccess } from '../../../core/store/actions/general.actions';
import { RESET_LOADING_BODY } from '../../../shared/constants/store.constants';
import { IAppState } from '../../../core/store/state/app.state';
import { switchMap, catchError } from 'rxjs/operators';
import { of, noop, Observable, EMPTY } from 'rxjs';
import { OPTIONS_CONST } from '../../../shared/constants/options.constants';

@Injectable()
export class OptionEffects {

  getOptionHomeRouted$ = createEffect(() => this.actions$.pipe(
    ofRoute('producto/:id'),
    switchMap(_ => {
      if (!(window.location.href).match(/plantilla/)
        && !(window.location.href).match(/etapa/)
        && !(window.location.href).match(/tarea/)
      ) {
        return of(new GetOptionSuccess(OPTIONS_CONST.data[0]));
      } else {
        return new Observable<Action>();
      }
    }),
    catchError(_ => {
      //   return noop;
      return EMPTY;
    })
  ));

  getOptionHomeNoRouted$ = createEffect(() => this.actions$.pipe(
    ofRoute('panel'),
    switchMap(_ => {
      if (!(window.location.href).match(/plantilla/)
        && !(window.location.href).match(/etapa/)
        && !(window.location.href).match(/tarea/)
      ) {
        return of(new GetOptionSuccess(OPTIONS_CONST.data[0]));
      } else {
        return new Observable<Action>();
      }
    }),
    catchError(_ => {
      //   return noop;
      return EMPTY;
    })
  ));

  getOptionTaskRouted$ = createEffect(() => this.actions$.pipe(
    ofRoute(/tarea/),
    switchMap(_ => {
      const indexData = 3;
      return of(new GetOptionSuccess(OPTIONS_CONST.data[indexData]));
    }),
    catchError(_ => {
      //   return noop;
      return EMPTY;
    })
  ));

  getOptionTemplateRouted$ = createEffect(() => this.actions$.pipe(
    ofRoute(/plantilla/),
    switchMap(_ => {
      const indexData = 2;
      return of(new GetOptionSuccess(OPTIONS_CONST.data[indexData]));
    }),
    catchError(_ => {
      //   return noop;
      return EMPTY;
    })
  ));

  getOptionStageRouted$ = createEffect(() => this.actions$.pipe(
    ofRoute(/etapa/),
    switchMap(_ => {
      const indexData = 1;
      return of(new GetOptionSuccess(OPTIONS_CONST.data[indexData]));
    }),
    catchError(_ => {
      //   return noop;
      return EMPTY;
    })
  ));

  getOptions$ = createEffect(() => this.actions$.pipe(
    ofType<GetOptions>(EOptionActions.GetOptionsAction),
    switchMap((payload) => {
      return this.optionService.getAllOptions();
    }),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      return of(new GetOptionsSuccess(response.data));
    })
  ));

  constructor(
    private optionService: OptionService,
    private actions$: Actions,
    private store: Store<IAppState>
  ) { }
}
