import { EGeneralActions, GetLoading, GetLoadingSuccess } from '../../../core/store/actions/general.actions';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class GeneralEffects {

  getLoading$ = createEffect(() => this.actions$.pipe(
    ofType<GetLoading>(EGeneralActions.GetLoadingAction),
    switchMap((action) => of(new GetLoadingSuccess((action.payload))))
  ));

  constructor(
    private actions$: Actions
  ) { }
}
