/* Angular */

import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* App */
import { AppMaterialModule } from '../../../../../app/app-material.module';
import { SharedModule } from '../../../../../app/shared/components/shared.module';

/* This */
import { ListStageComponent } from './pages/list-stage/list-stage.component';
import { ListStageContainerComponent } from './containers/list-stage-container/list-stage-container.component';
import { SwitchStageFormComponent } from './components/switch-stage-form/switch-stage-form.component';
import { SwitchStageFormContainerComponent } from './containers/switch-stage-form-container/switch-stage-form-container.component';
import { StagesOrderPreviewComponent } from './components/stages-order-preview/stages-order-preview.component';
import { StagesOrderPreviewContainerComponent } from './containers/stages-order-preview-container/stages-order-preview-container.component';
import { StageFormComponent } from './pages/stage-form/stage-form.component';
import { StageFormContainerComponent } from './containers/stage-form-container/stage-form-container.component';
import { StateFormComponent } from './pages/stage-form/components/state-form/state-form.component';
import { CreateStageFormComponent } from './pages/create-stage-form/create-stage-form.component';
import { CreateStageFormContainerComponent } from './containers/create-stage-form-container/create-stage-form-container.component';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';

@NgModule({
    imports: [
        CommonModule,
        AppMaterialModule,
        ReactiveFormsModule,
        SharedModule,
        BcIconModule
    ],
    declarations: [
        ListStageComponent,
        ListStageContainerComponent,
        SwitchStageFormComponent,
        SwitchStageFormContainerComponent,
        StagesOrderPreviewComponent,
        StagesOrderPreviewContainerComponent,
        StageFormComponent,
        StageFormContainerComponent,
        StateFormComponent,
        CreateStageFormComponent,
        CreateStageFormContainerComponent]
})

export class StageDashboardModule { }
