export const environment = {
  production: false,
  apiEndPoint: 'https://clientes-ext-qa.apps.ambientesbc.com/trackingcliente/admintracking/api/v1',
  securityEndPoint: 'https://clientes-ext-qa.apps.ambientesbc.com/trackingcliente/authmanager/api/v1',
  azureActiveDirectory: {
    clientId: '2546f58f-612b-4f84-8e8f-50c433ed2c09',
    tenantId: '428f4e2e-13bf-4884-b364-02ef9af41a1d',
    resource: 'http://localhost:5000/',
    redirectUri: 'https://tracking-ou-qa.apps.ambientesbc.com/frameredirect',
  },
  apiProtected: 'https://clientes-ext-qa.apps.ambientesbc.com/trackingcliente/**',
  host:'https://tracking-ou-qa.apps.ambientesbc.com',
  enableMsal:"true",
};
