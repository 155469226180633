/* Angular */

import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* This */
import { ListTemplateComponent } from './pages/list-template/list-template.component';
import { TemplateInfoBoxComponent } from './pages/template-form/components/template-info-box/template-info-box.component';
import { ListTemplateContainerComponent } from './containers/list-template-container/list-template-container.component';

/* App */
import { AppMaterialModule } from '../../../../../app/app-material.module';
import { TemplateFormComponent } from './pages/template-form/template-form.component';
import { TemplateFormContainerComponent } from './containers/template-form-container/template-form-container.component';
import { SwitchTemplateFormComponent } from './components/switch-template-form/switch-template-form.component';
import { SharedModule } from '../../../../../app/shared/components/shared.module';
import { SwitchTemplateFormContainerComponent } from './containers/switch-template-form-container/switch-template-form-container.component';

@NgModule({
    imports: [
        CommonModule,
        AppMaterialModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        ListTemplateComponent,
        ListTemplateContainerComponent,
        TemplateFormComponent,
        TemplateFormContainerComponent,
        TemplateInfoBoxComponent,
        SwitchTemplateFormComponent,
        SwitchTemplateFormContainerComponent
    ]
})

export class TemplateDashboardModule { }
