import { FolderService } from './../../services/folder.service';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IResponse } from '../../../shared/models/other/http/Response.interface';
import { GetLoadingSuccess } from '../../../core/store/actions/general.actions';
import { RESET_LOADING_BODY } from '../../../shared/constants/store.constants';
import { SubtypeService } from '../../../core/services/subtype.service';
import { IAppState } from '../../../core/store/state/app.state';

import {
  GetSubtypesSuccess,
  ESubtypeActions,
  GetSubtypeSuccess,
  GetSubtypes
} from '../../../core/store/actions/subtype.actions';

import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofRoute, mapToParam } from '../../../../app/shared/pipes/router-store.pipe';

@Injectable()
export class SubtypeEffects {

  subtypeRouted$ = createEffect(() => this.actions$.pipe(
    ofRoute('producto/:id'),
    mapToParam<string>('id'),
    switchMap(id => this.subtypeService.getSubtypeById(id)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      return of(new GetSubtypeSuccess(response.data[0]));
    }
    )
  ));

  getSubtypes$ = createEffect(() => this.actions$.pipe(
    ofType<GetSubtypes>(ESubtypeActions.GetSubtypesAction),
    switchMap(() => {
      return this.folderService.getFolderByEmailFromToken();
    }),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      return of(new GetSubtypesSuccess(response.data));
    })
  ));

  constructor(
    private subtypeService: SubtypeService,
    private folderService: FolderService,
    private actions$: Actions,
    private store: Store<IAppState>
  ) { }
}
