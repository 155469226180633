import { IState } from '../../../shared/models/state/State.interface';
import { IStage } from '../../../shared/models/stage/Stage.interface';
import { Action } from '@ngrx/store';

export enum EStageActions {
    GetStageAction = '[Stage] Get Stage',
    GetStageSuccessAction = '[Stage] Get Stage Success',
    GetStagesAction = '[Stage] Get Stages',
    GetStagesSuccessAction = '[Stage] Get Stages Success',
    UpdateStagesOrderAction = '[Stage] Update Stages Order',
    UpdateStagesOrderSuccessAction = '[Stage] Update Stages Order Success',
    DeleteStageAction = '[Stage] Delete Stage',
    DeleteStageSuccessAction = '[Stage] Delete Stage Success',
    UpdateStageNotificationAction = '[Stage] Update Stage Notification',
    UpdateStageNotificationSuccessAction = '[Stage] Update Stage Notification Success',
    SaveStageAction = '[Stage] Save Stage',
    SaveStageSuccessAction = '[Stage] Save Stage Success',
    UpdateStageAction = '[Stage] Update Stage',
    UpdateStageSuccessAction = '[Stage] Update Stage Success',
    GetStateAction = '[Stage - State] Get State',
    GetStateSuccessAction = '[Stage - State] Get State Success',
    GetStatesAction = '[Stage - State] Get States',
    GetStatesSuccessAction = '[Stage - State] Get States Success',
    SaveStateAction = '[Stage - State] Save State',
    SaveStateSuccessAction = '[Stage - State] Save State Success',
    DeleteStateAction = '[Stage - State] Delete State',
    DeleteStateSuccessAction = '[Stage - State] Delete State Success'
}

export class GetStages implements Action {
    public readonly type = EStageActions.GetStagesAction;
    constructor(public payload: string) { }
}

export class GetStagesSuccess implements Action {
    public readonly type = EStageActions.GetStagesSuccessAction;
    constructor(public payload: IStage[]) { }
}

export class GetStage implements Action {
    public readonly type = EStageActions.GetStageAction;
    constructor(public payload: string) { }
}

export class UpdateStagesOrderSuccess implements Action {
    public readonly type = EStageActions.UpdateStagesOrderSuccessAction;
    constructor(public payload: IStage[]) { }
}

export class UpdateStagesOrder implements Action {
    public readonly type = EStageActions.UpdateStagesOrderAction;
    constructor(public stages: IStage[]) { }
}

export class GetStageSuccess implements Action {
    public readonly type = EStageActions.GetStageSuccessAction;
    constructor(public payload: IStage) { }
}

export class UpdateStageNotification implements Action {
    public readonly type = EStageActions.UpdateStageNotificationAction;
    constructor(public payload: IStage) { }
}

export class UpdateStageNotificationSuccess implements Action {
    public readonly type = EStageActions.UpdateStageNotificationSuccessAction;
    constructor(public payload: IStage) { }
}

export class GetStates implements Action {
    public readonly type = EStageActions.GetStatesAction;
    constructor(public payload: string) { }
}

export class GetStatesSuccess implements Action {
    public readonly type = EStageActions.GetStatesSuccessAction;
    constructor(public payload: IState[]) { }
}

export class GetState implements Action {
    public readonly type = EStageActions.GetStateAction;
    constructor(public payload: string) { }
}

export class GetStateSuccess implements Action {
    public readonly type = EStageActions.GetStateSuccessAction;
    constructor(public payload: IState[]) {
    }
}

export class SaveStage implements Action {
    public readonly type = EStageActions.SaveStageAction;
    constructor(public payload: IStage) { }
}

export class SaveStageSuccess implements Action {
    public readonly type = EStageActions.SaveStageSuccessAction;
    constructor(public payload: IStage) {
    }
}

export class UpdateStage implements Action {
    public readonly type = EStageActions.UpdateStageAction;
    constructor(public payload: IStage) { }
}

export class UpdateStageSuccess implements Action {
    public readonly type = EStageActions.UpdateStageSuccessAction;
    constructor(public payload: IStage) {
    }
}

export class SaveState implements Action {
    public readonly type = EStageActions.SaveStateAction;
    constructor(public payload: IState) { }
}

export class SaveStateSuccess implements Action {
    public readonly type = EStageActions.SaveStateSuccessAction;
    constructor(public payload: IState[]) {
    }
}

export class DeleteState implements Action {
    public readonly type = EStageActions.DeleteStateAction;
    constructor(public payload: IState) { }
}

export class DeleteStateSuccess implements Action {
    public readonly type = EStageActions.DeleteStateSuccessAction;
    constructor(public payload: IState[]) {
    }
}

export class DeleteStage implements Action {
    public readonly type = EStageActions.DeleteStageAction;
    constructor(public payload: IStage) { }
}

export class DeleteStageSuccess implements Action {
    public readonly type = EStageActions.DeleteStageSuccessAction;
    constructor(public payload: void) {
    }
}

export type StageActions = GetStages | GetStagesSuccess | GetStage | GetStageSuccess | GetStates |
    GetStatesSuccess | GetState | GetStateSuccess | UpdateStagesOrder | UpdateStagesOrderSuccess |
    UpdateStageNotification | UpdateStageNotificationSuccess | SaveState | SaveStateSuccess | DeleteState | DeleteStateSuccess |
    DeleteStage | DeleteStageSuccess | SaveStage | SaveStageSuccess | UpdateStage | UpdateStageSuccess;
