import { PopupComponent } from '../../../../../../shared/components/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ITemplate } from '../../../../../../shared/models/template/Template.interface';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-switch-template-form',
  templateUrl: './switch-template-form.component.html',
  styleUrls: ['./switch-template-form.component.scss']
})
export class SwitchTemplateFormComponent implements OnInit, OnChanges {

  @Input()
  type: string;

  @Input()
  template: ITemplate;

  @Output()
  templateUpdateNotifications$: EventEmitter<ITemplate> = new EventEmitter();

  checked: boolean;


  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.checked = this.initSwitchEnabled();
  }

  onChange(event: MatSlideToggleChange): void {
    this.checked = event.checked;
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '320px',
      data: { title: '¡Espera!', message: `${this.getMessage(event.checked)}`, detail: this.getDetail(event.checked),
      action: false,
      singleButton: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.type === 'sms') {
          this.template.smsEnabled = !this.template.smsEnabled;
        } else {
          this.template.emailEnabled = !this.template.emailEnabled;
        }
        this.templateUpdateNotifications$.emit(this.template);
      } else {
        this.checked = !this.checked;
      }
    });
  }

  getDetail(checked: boolean): string {
    if (!checked) {
      if (this.type === 'sms') {
        return `Recuerda que deshabilitando esta opción, los clientes no serán notificados en sms para la etapa
        ${this.template.stageName}`;
      } else {
        return `Recuerda que deshabilitando esta opción, los clientes no serán notificados en email para la etapa
        ${this.template.stageName}`;
      }
    } else {
      if (this.type === 'sms') {
        return `Recuerda que habilitando esta opción, los clientes serán notificados en sms para la etapa
        ${this.template.stageName}`;
      } else {
        return `Recuerda que habilitando esta opción, los clientes serán notificados en email para la etapa
        ${this.template.stageName}`;
      }
    }
  }

  getMessage(checked: boolean): string {
    if (!checked) {
      return `¿Estás seguro que quieres deshabilitar las notificaciones para ${this.type}?`;
    } else {
      return `¿Estás seguro que quieres habilitar las notificaciones para ${this.type}?`;
    }
  }

  initSwitchEnabled(): boolean {
    return (this.template) ? ((this.type === 'sms') ? this.template.smsEnabled : this.template.emailEnabled) : false;
  }
}
