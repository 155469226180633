<section class="stage">
    <h3><img src="{{property.option.iconActive}}" alt="Icon"> {{property.label.title}}</h3>
    <p>{{property.label.list}}</p>
    <app-loading *ngIf="componentIsLoading()"></app-loading>
    <main *ngIf="!componentIsLoading()">
        <app-no-content *ngIf="!areThereStages()"></app-no-content>
        <div cdkDropList class="stage-list" (cdkDropListDropped)="drop($event)" *ngIf="areThereStages()">
            <table aria-describedby="lista de plantillas">
                <tr>
                    <th class="text-center" id="stage-order">
                        <h4>Orden</h4>
                    </th>
                    <th id="stage-name">
                        <h4>Nombre de la etapa</h4>
                    </th>
                    <th class="text-center" id="stage-number-of-states">
                        <h4># Estados</h4>
                    </th>
                    <th class="text-center" id="stage-notification">
                        <h4>Notificaciones</h4>
                    </th>
                    <th id="stage-grip-line">
                    </th>
                </tr>
                <tr class="stage-box" *ngFor="let stage of getStagesByExpire(false)" cdkDrag id="{{stage.codStage}}">
                    <img *cdkDragPreview src="assets/icons/on/DragAndDrop.svg" [alt]="stage.name" height="26px"
                        width="auto">
                    <td                        
                        [className]="stage.position <= 0 || stage.position > 8 ? 'invalid-order text-center' : 'text-center'">
                        {{stage.position}}
                    </td>
                    <td (click)="navigateToStage(stage)">
                        <u>{{stage.name}}</u>
                    </td>
                    <td class="text-center">
                        {{getStatesOf(stage.codStage)}}
                    </td>
                    <td class="text-center">
                        <app-switch-stage-form-container [stage]="stage">
                        </app-switch-stage-form-container>
                    </td>
                    <td class="dragndrop">
                        <bc-icon class="bc-svg-icon" height="30px" aria-hidden="true" aria-label="prueba">bars
                        </bc-icon>
                    </td>
                </tr>
            </table>
        </div>

        <div class="main-vencimientos bc-margin-top-3" *ngIf="getStagesByExpire(true)?.length">
            <h5>Vencimientos</h5>
            <div class="stage-list-v bc-margin-top-1">
                <table aria-label="lista vencimientos" aria-describedby="lista vencimientos">
                    <tr class="stage-box" *ngFor="let stage of getStagesByExpire(true)">
                        <td class="text-center">
                            N/A
                        </td>
                        <td (click)="navigateToStage(stage)">
                            <u>{{stage.name}}</u>
                        </td>
                        <td class="text-center">
                            N/A
                        </td>
                        <td class="text-center">
                            <app-switch-stage-form-container [stage]="stage">
                            </app-switch-stage-form-container>
                        </td>
                        <td>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <button type="button" class="btn btn-tertiary" [disabled]="buttonStatus" (click)="onCreateNewStage()"><em
                class="fas fa-plus-circle"></em> Crear nueva etapa</button>
        <app-stages-order-preview-container *ngIf="areThereStages()"></app-stages-order-preview-container>
    </main>
</section>