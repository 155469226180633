import { selectLoading } from '../../../../../../core/store/selectors/general.selectors';
import { UpdateFieldsTemplate } from '../../../../../../core/store/actions/field.actions';
import { IFieldStage } from '../../../../../../shared/models/field/FieldStage.interface';
import { selectSelectedTemplateFields } from '../../../../../../core/store/selectors/field.selector';
import { UpdateTemplate } from '../../../../../../core/store/actions/template.actions';
import { ITemplate } from '../../../../../../shared/models/template/Template.interface';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { GetLoading } from '../../../../../../core/store/actions/general.actions';
import { IAppState } from '../../../../../../core/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { selectSelectedStage } from '../../../../../../core/store/selectors/stage.selectors';
import { selectSelectedTemplate } from '../../../../../../core/store/selectors/template.selectors';
import { selectSelectedSubtypeFields } from '../../../../../../../app/core/store/selectors/field.selector';

@Component({
  selector: 'app-template-form-container',
  templateUrl: './template-form-container.component.html',
  host: {
    class: 'container-customized'
  }
})
export class TemplateFormContainerComponent implements OnInit {

  template$ = this.store.pipe(select(selectSelectedTemplate));
  stage$ = this.store.pipe(select(selectSelectedStage));
  loading$ = this.store.pipe(select(selectLoading));
  fields$ = this.store.pipe(select(selectSelectedSubtypeFields));
  fieldsTemplate$ = this.store.pipe(select(selectSelectedTemplateFields));

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.templateSelected }));
  }

  updateTemplate(template: ITemplate) {
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.templateSelected }));
    this.store.dispatch(new UpdateTemplate(template));
  }

  updateEmailFields(fields: IFieldStage[]) {
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.templateSelected }));
    this.store.dispatch(new UpdateFieldsTemplate(fields, fields[0].codStage + ''));
  }
}
