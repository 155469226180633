import { IFolderSubtype } from './../../../../shared/models/folder/FolderSubtype.interface';
import { ILoading } from '../../../../shared/models/other/store/Loading.interface';
import { ELoadingContent } from '../../../../shared/models/other/store/Loading.enum';
import { isComponentLoading } from '../../../../shared/constants/store.constants';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ISubtype } from '../../../../shared/models/subtype/Subtype.interface';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnChanges {

  @Input()
  subtypes: IFolderSubtype[] = [];

  @Input()
  subtype: ISubtype;

  @Input()
  loading: ILoading;

  @Output()
  subtypeSelected$: EventEmitter<string> = new EventEmitter();

  selected: string;
  alreadySelected: boolean;
  loadSelected: boolean;

  // Library SDB

  objSelect = {
    values: [
      { text: 'Option 1' },
      { text: 'Option 2' },
      { text: 'Option 3' },
      { text: 'Option 4' },
      { text: 'Option 5' }
    ],
    valuesIcon: [
      { text: 'Option 1', icon: 'usd-circle' },
      { text: 'Option 2', icon: 'lock-alt' }
    ],
    values7: [
      { text: 'Option 1' },
      { text: 'Option 2' },
      { text: 'Option 3' },
      { text: 'Option 4' },
      { text: 'Option 6' },
      { text: 'Option 7' },
      { text: 'Option 8' },
      { text: 'Option 9' }
    ],
    selectedValues: ['Option 2', 'Option 3', 'Option 5'],
    placeholder: 'Selecciona el tipo de solicitud',
    iconLeft: 'address-card',
    isMultipleChk: true
  };

  valueInput = [];

  values: any[] = [];

  constructor() { }

  ngOnInit() {
    this.alreadySelected = false;
    this.loadSelected = true;
    // library SDB
    this.service();
  }

  private isNotSelected(): boolean {
      let notSelected = true;
      if (this.selected === undefined) {
        if (this.subtypes !== undefined && this.subtypes !== null) {
          this.subtypes.forEach(f => {
            f.subtypeList.forEach(s => {
              if (this.subtype.codSubtype === s.codSubtype && this.selected === undefined) {
                this.selected = f.folder.codFolder + '/' + s.codSubtype;
                notSelected = false;
              }
            });
          });
        }
      } else {
        notSelected = false;
      }
      return notSelected;
  }

  ngOnChanges() {
    if (this.subtype && !this.alreadySelected) {
      this.alreadySelected = !this.alreadySelected;
    }
    do {
      if (this.subtypes !== undefined && this.subtypes !== null) {
        this.loadSelected = this.subtypes.length > 0 && this.isNotSelected();
      }
    } while (this.loadSelected);
  }

  navigateToSubtype(subtype: string): void {
    this.alreadySelected = !this.alreadySelected;
    const s = subtype.split('/')[1];
    this.selected = subtype;
    this.subtypeSelected$.emit(s);
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.subtypeList);
  }

  // Library SDB
  showSelectedValue(value: string) {
    console.log(value);
  }

  service() {
    const valTemp = [];
    fetch('https://jsonplaceholder.typicode.com/users')
      .then(response => response.json())
      .then(
        json => {
          json.forEach(element => {
            valTemp.push({ text: element.name });
          });
          this.valueInput = valTemp;
        }
      );
  }
}
