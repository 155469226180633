import { ILoading } from '../../../shared/models/other/store/Loading.interface';
import { Action } from '@ngrx/store';

export enum EGeneralActions {
    GetLoadingAction = '[General] Get Loading',
    GetLoadingSuccessAction = '[General] Get Loading Success',
}

export class GetLoading implements Action {
    public readonly type = EGeneralActions.GetLoadingAction;
    constructor(public payload: ILoading) { }
}

export class GetLoadingSuccess implements Action {
    public readonly type = EGeneralActions.GetLoadingSuccessAction;
    constructor(public payload) { }
}


export type GeneralActions = GetLoading | GetLoadingSuccess;
