import { GetOptions } from '../../../../core/store/actions/option.actions';
import { ELoadingContent } from '../../../../shared/models/other/store/Loading.enum';
import { GetLoading } from '../../../../core/store/actions/general.actions';
import { selectSelectedOption, selectOptionList } from '../../../../core/store/selectors/option.selector';
import { IOption } from '../../../../shared/models/option/Option.interface';
import { RouterGo } from '../../../../core/store/actions/router.actions';
import { IAppState } from '../../../../core/store/state/app.state';
import { selectLoading } from '../../../../core/store/selectors/general.selectors';
import { select, Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-sidebar-container',
  templateUrl: './sidebar-container.component.html'
})
export class SidebarContainerComponent implements OnInit {

  loading$ = this.store.pipe(select(selectLoading));
  option$ = this.store.pipe(select(selectSelectedOption));
  options$ = this.store.pipe(select(selectOptionList));

  constructor(private store: Store<IAppState>, private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.store.dispatch(new GetOptions());
    this.activatedRoute.params.subscribe(params => {
      const param = params['id'];
      if (param) {
        this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.subtypeSelected }));
      }
    });
  }

  setStoreDispatch(path: string): void {
    this.store.dispatch(new RouterGo({
      path: [path]
    }));
  }

  setStoreDispatchRelativeTo(path: string): void {
    this.store.dispatch(new RouterGo({
      path: [path], 
      extras: {
        relativeTo: this.activatedRoute
      }
    }));
  }

  navigateToOption(option: IOption) {
    const case1 = 1;
    const case2 = 2;
    const case3 = 3;
    const case4 = 4;
    switch (option.codOption) {
      case case1: {
        this.activatedRoute.params.subscribe(params => {
          const param = params['id'];
          if (param) {
            this.setStoreDispatch(`producto/${param}`);
          } else {
              this.setStoreDispatch(`panel`);
          }
        });
        break;
      }
      case case2: {
        this.setStoreDispatchRelativeTo(`etapas`);
        break;
      }
      case case3: {
        this.setStoreDispatchRelativeTo(`plantillas`);
        break;
      }
      case case4: {
        this.setStoreDispatchRelativeTo(`tareas`);
        break;
      }
      default:
        break;
    }
  }
}
