import { IState } from '../../../shared/models/state/State.interface';
import { IStage } from '../../../shared/models/stage/Stage.interface';

export interface IStageState {
    stages: IStage[];
    states: IState[];
    statesOfStage: IState[];
    selectedStage: IStage;
}

export const initialStageState: IStageState = {
    stages: [],
    states: [],
    statesOfStage: [],
    selectedStage: null
};
