import { ITaskState } from '../../store/state/task.state';
import {initialTaskState} from '../../store/state/task.state';
import { TaskActions, ETaskActions } from '../../store/actions/task.actions';

export function taskReducer(
    state = initialTaskState,
    action: TaskActions
): ITaskState {
    if (action.type === ETaskActions.GetTasksSuccessAction) {
        return {
            ...state,
            tasks: action.payload
        };
    } else {
        return state;
    }
}
