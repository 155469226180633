import { RouterGo } from '../../../../core/store/actions/router.actions';
import { selectSubtypeList, selectLoading } from '../../../../../app/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../../../core/store/state/app.state';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-card-container',
  templateUrl: './product-card-container.component.html'
})
export class ProductCardContainerComponent implements OnInit {

  subtypes$ = this.store.pipe(select(selectSubtypeList));
  loading$ = this.store.pipe(select(selectLoading));

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
  }

  navigateToSubtype(subtype: string) {
    this.store.dispatch(new RouterGo({ path: [`producto/${subtype}`] }));
  }

}
