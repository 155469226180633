import { IState } from '../../shared/models/state/State.interface';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../shared/models/other/http/Response.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private http: HttpClient) { }

  getStatesOfSubtype(subtypeId: string): Observable<IResponse> {
    return this.http.get<IResponse>
      (`${environment.apiEndPoint}/states/subtype/${subtypeId}`);
  }

  getStatesOfStage(stageId: string): Observable<IResponse> {
    return this.http.get<IResponse>
      (`${environment.apiEndPoint}/states/stage/${stageId}`);
  }

  saveState(state: IState): Observable<IResponse> {
    return this.http.post<IResponse>(`${environment.apiEndPoint}/states`, state);
  }

  deleteState(state: IState): Observable<IResponse> {
    return this.http.delete<IResponse>(`${environment.apiEndPoint}/states/${state.codState}`);
  }
}
