import { noop } from 'rxjs';
import { FIELD_VARIABLE_REGEX } from '../../shared/constants/regex.constants';
import { IField } from './../models/field/Field.interface';

export function nameToNumberAsVariable(message: string, fields: IField[], reverse: boolean = false): string {
    if (message) {
        let field: IField;
        let word = '';
        message.split('').forEach(c => {
            if (c === '[') {
                word += c;
            } else if (c === ']') {
                word += c;
                field = fields.find(f => `[${!reverse ? f.name : f.codField}]` === word);
                if (field) {
                    message = message.split(word).join(`[${!reverse ? field.codField : field.name}]`);
                } else {
                    noop();
                }
                word = '';
            } else {
                if (word.length > 0) {
                    word += c;
                } else {
                    noop();
                }
            }
        });
    } else {
        noop();
    }
    return message;
}

export function convertMessageToExample(message: string, fields: IField[]): string {
    if (message) {
        let word = '';
        message.split('').forEach(c => {
            if (c === '[') {
                word += c;
            } else if (c === ']') {
                word += c;
                if (word.match(FIELD_VARIABLE_REGEX)) {
                    const wordAux = (fields) ? fields.find(f => `[${f.name}]` === word) : undefined;
                    if (wordAux) {
                        message = message.split(word).join(`<strong class="highlight-success">${wordAux.example}</strong>`);
                    } else {
                        message = message.split(word).join(`<strong class="highlight-error">${word}</strong>`);
                    }
                    word = '';
                } else {
                    noop();
                }
            } else if (word.length > 0) {
                word += c;
            } else {
                noop();
            }
        });
    }
    return message;
}
