import { UpdateTemplateNotifications } from '../../../../../../core/store/actions/template.actions';
import { IAppState } from '../../../../../../core/store/state/app.state';
import { Store } from '@ngrx/store';
import { ITemplate } from '../../../../../../shared/models/template/Template.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-switch-template-form-container',
  templateUrl: './switch-template-form-container.component.html'
})
export class SwitchTemplateFormContainerComponent implements OnInit {

  @Input()
  type: string;

  @Input()
  template: ITemplate;

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
  }

  updateTemplateNotifications(template: ITemplate) {
    this.store.dispatch(new UpdateTemplateNotifications(template));
  }

}
