import { ProductCardContainerComponent } from './containers/product-card-container/product-card-container.component';
import { ListTaskContainerComponent } from './modules/task/containers/list-task-container/list-task-container.component';
import { ListTaskFormContainerComponent } from './modules/task/containers/list-task-form-container/list-task-form-container.component';

import {
    CreateStageFormContainerComponent
} from './modules/stage/containers/create-stage-form-container/create-stage-form-container.component';
import { HomeProductContainerComponent } from './containers/home-product-container/home-product-container.component';
import { StageFormContainerComponent } from './modules/stage/containers/stage-form-container/stage-form-container.component';
import { NotFoundComponent } from '../../shared/components/not-found/not-found.component';
import { TemplateFormContainerComponent } from './modules/template/containers/template-form-container/template-form-container.component';
import { ListTemplateContainerComponent } from './modules/template/containers/list-template-container/list-template-container.component';
import { HomeContainerComponent } from '../dashboard/containers/home-container/home-container.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ListStageContainerComponent } from './modules/stage/containers/list-stage-container/list-stage-container.component';
import { MsalGuard } from '@azure/msal-angular';
import { UnauthorizedComponent } from '../../shared/components/unauthorized/unauthorized.component';
import { ServiceUnavailableComponent } from '../../shared/components/service-unavailable/service-unavailable.component';


const dashboardRoutes: Routes = [
    { path: 'frameredirect', redirectTo: 'panel', pathMatch: 'full' },
    {
        path: 'panel', component: HomeContainerComponent, canActivate: [MsalGuard], children:
            [
                { path: '', component: ProductCardContainerComponent },
                { path: 'etapas', component: ProductCardContainerComponent },
                { path: 'plantillas', component: ProductCardContainerComponent },
                { path: 'tareas', component: ProductCardContainerComponent },
            ]
    },
    {
        path: 'producto/:id', component: HomeContainerComponent, canActivate: [MsalGuard], children:
            [
                // Product home - statistics
                { path: '', component: HomeProductContainerComponent },
                // Templatesx
                { path: 'plantillas', component: ListTemplateContainerComponent },
                { path: 'plantilla/:id', component: TemplateFormContainerComponent },
                // Stages
                { path: 'etapas', component: ListStageContainerComponent },
                { path: 'etapas/crear', component: CreateStageFormContainerComponent },
                { path: 'etapa/:id', component: StageFormContainerComponent },
                // Tasks
                { path: 'tareas', component: ListTaskContainerComponent },
                { path: 'tarea/:id', component: ListTaskFormContainerComponent },
            ]
    },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'service-unavailable', component: ServiceUnavailableComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(dashboardRoutes)
    ],
    exports: [
        RouterModule
    ]
})

export class DashboardRoutingModule { }
