import { IPopup } from '../../../shared/models/other/shared/Popup.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ALPHANUMERIC_UTF8_REGEX } from '../../../shared/constants/regex.constants';



@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, OnDestroy {

  intervalClose: any = null;
  nameFormGroup: FormGroup = this.formBuilder.group({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(this.getMaxlengthName()),
      Validators.pattern(ALPHANUMERIC_UTF8_REGEX),
    ])
  });
  closeTime = 0;

  getMaxlengthName(): number {
    const maxlengthName = 45;
    return maxlengthName;
  }

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPopup, private formBuilder: FormBuilder) { }

  ngOnDestroy(): void {
    if (this.intervalClose) {
      clearInterval(this.intervalClose);
    }
  }

  ngOnInit() {
    if (this.data.time) {
      const seconds = 1000;
      this.closeTime = Date.now() + this.data.time;
      this.intervalClose = setInterval(() => {
        if (Date.now() >= this.closeTime) {
          this.onButtonClick(true);
        }
      }, seconds);
    }
  }

  onButtonClick(action: boolean): void {
    this.dialogRef.close(action);
  }

  onCreateButtonClick(): void {
    this.dialogRef.close(this.nameFormGroup.value.name);
  }
}
