import { MatSnackBar } from '@angular/material/snack-bar';
import { ESessionContent } from './../../../../shared/models/session/Session.enum';
import { noop } from 'rxjs';
import { PopupComponent } from './../../../../shared/components/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { IAppState } from '../../../../core/store/state/app.state';
import { selectSelectedSubtype } from '../../../../../app/core';
import { AuthManagerService } from '../../../../../app/core/services/auth-manager.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-container',
  templateUrl: './home-container.component.html'
})
export class HomeContainerComponent implements OnInit {

  subtype$ = this.store.pipe(select(selectSelectedSubtype));

  sessionPopupOpened: boolean;
  sessionPopupInAction: boolean;
  singlePopupAction = true;

  sessionPopupMessage: string;
  sessionPopupTitle = '¡Error!';
  sessionPopupDetail: string;
  sessionPopupTime: number = null;

  sessionCloseToExpireNotification = true;

  isLogginIn = false;

  constructor(
    private authManager: AuthManagerService,
    public dialog: MatDialog,
    private authService: MsalService,
    private store: Store<IAppState>,
    private notification: MatSnackBar,
    private router: Router
  ) {
    this.sessionPopupOpened = false;
    this.sessionPopupInAction = true;
  }

  ngOnInit() {
    const delayTime = 3000;
    setInterval(() => {
      if (sessionStorage.getItem('firstLogin') === '1' || 
      sessionStorage.getItem('firstLogin') === null) {    
        this.onDoFirstLoggin();
      } else {
        this.authManager.retrieveSession()
          .subscribe({
            next: data => {this.manageSession(data);}, 
            error: err => {
              const internaServerError = 500;
              if (err?.status === internaServerError && err?.error.message === ESessionContent.expired_jwt) {
                this.onLogout();
              } else {
                this.sessionPopupOpened = true;
                this.manageErrorOnValidation();
              }
            }}
          );
      }
    }, delayTime);
  }

  onDoFirstLoggin(): void {
    sessionStorage.setItem('firstLogin', '0');
    this.createNewSession();
  }

  createNewSession(): void {
    if (!this.isLogginIn) {
      this.isLogginIn = true;
      this.authManager.createSession().subscribe({ 
        next: r => {
          if (JSON.parse(JSON.stringify(r)).data === ESessionContent.expired) {
            this.sessionPopupTitle = '¡Expiró!';
            this.sessionPopupMessage = 'Tu sesión ha expirado. Por favor, vuelve a ingresar.';
            this.sessionPopupDetail = '';
            this.sessionPopupOpened = true;
            this.singlePopupAction = true;
          } else {
            this.isLogginIn = false;
            this.dialog.closeAll();
            this.notification.open(`¡Éxito! La sesión de usuario se verificó exitosamente`,
              'cerrar', {
              duration: 7000,
              verticalPosition: 'top',
              panelClass: 'tracking-toast-bar-success'
            });
          }
        }, 
        error: _ => {
          this.isLogginIn = false;
          this.notification.open(`¡Error! No pudimos verificar la sesión de usuario`,
            'cerrar', {
            duration: 7000,
            verticalPosition: 'top',
            panelClass: 'tracking-toast-bar-error'
          });
        }});
    }
  }

  setNotificationSessionCloseToExpire(): void {
    if (this.sessionCloseToExpireNotification) {
      this.notification.open(`¡Atención! La sesión de usuario se vencerá pronto`,
        'cerrar', {
        duration: 15000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-warning'
      });
      this.sessionCloseToExpireNotification = false;
    }
  }

  takeActionsOnResponse(manager): void {
    if (manager.data === ESessionContent.must_create_session) {
      this.createNewSession();
    } else if (manager.data.response === ESessionContent.different_browser) {
      this.sessionPopupMessage = 'Parece que tienes una sesión en tu mismo dispositivo, pero en diferente navegador. ' +
        '¿Te gustaría continuar con la sesión aquí?';
      this.sessionPopupDetail = `Actualmente tienes una sesión en ${manager.data.newBrowser} y accediste` +
        ` nuevamente a través de ${manager.data.lastBrowser}`;
      this.sessionPopupOpened = true;
      this.singlePopupAction = false;
    } else if (manager.data === ESessionContent.expired) {
      this.sessionPopupTitle = '¡Expiró!';
      this.sessionPopupMessage = 'Tu sesión ha expirado. Por favor, vuelve a ingresar.';
      this.sessionPopupDetail = '';
      this.sessionPopupOpened = true;
      this.singlePopupAction = true;
      this.sessionPopupTime = 600000;
    } else if (manager.data === ESessionContent.close_to_expire) {
      this.setNotificationSessionCloseToExpire();
    } else if (manager.data.response === ESessionContent.new_login) {
      this.sessionPopupMessage = 'Hemos identificado un nuevo inicio de sesión en otro dispositivo. ' +
        'Por motivos de seguridad tu sesión será cerrada.';
      this.sessionPopupDetail = `Detectamos un nuevo ingreso en otro dispositivo a través de ${manager.data.lastBrowser}`;
      this.sessionPopupOpened = true;
      this.singlePopupAction = true;
    } else if (manager.data === ESessionContent.ok) {
      noop();
    } else {
      this.sessionPopupMessage = 'La verificación de seguridad falló.';
      this.sessionPopupDetail = 'Ocurrió un error intentando verificar la sesión. Por favor, ingresa nuevamente.';
      this.sessionPopupOpened = true;
      this.singlePopupAction = true;
    }
  }

  manageErrorOnValidation(): void {
    if (this.sessionPopupOpened) {
      if (this.sessionPopupInAction) {
        this.sessionPopupInAction = false;
        const dialogRef = this.dialog.open(PopupComponent, {
          width: '320px',
          data: {
            title: '¡Error!',
            message: 'La verificación de seguridad falló.',
            detail: 'Espera mientras validamos tu sesión. Si el problema persiste, ' +
              'por favor intenta comunicandote con el equipo de soporte.',
            action: false,
            singleButton: true
          }
        });
        dialogRef.afterClosed().subscribe((_) => {
          this.sessionPopupInAction = true;
          this.sessionPopupOpened = false;
        });
      }
    }
  }

  manageSession(data): void {
    this.takeActionsOnResponse(JSON.parse(JSON.stringify(data)));
    if (this.sessionPopupOpened) {
      if (this.sessionPopupInAction) {
        this.sessionPopupInAction = false;
        const dialogRef = this.dialog.open(PopupComponent, {
          width: '320px',
          data: {
            title: this.sessionPopupTitle,
            message: this.sessionPopupMessage,
            detail: this.sessionPopupDetail,
            action: false,
            singleButton: this.singlePopupAction,
            time: this.sessionPopupTime
          }
        });

        dialogRef.afterClosed().subscribe((response) => {
          this.sessionPopupInAction = true;
          this.sessionPopupOpened = false;
          this.sessionPopupTime = null;
          if (this.singlePopupAction) {
            this.onLogout();
          } else {
            if (response) {
              this.createNewSession();
            } else {
              this.onLogout();
            }
          }
        });
      }
    }
  }

  onLogout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }

}
