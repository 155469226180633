import { IOption } from '../../../shared/models/option/Option.interface';
import { Action } from '@ngrx/store';

export enum EOptionActions {
    GetOptionAction = '[Option] Get Option',
    GetOptionSuccessAction = '[Option] Get Option Success',
    GetOptionsAction = '[Option] Get Options',
    GetOptionsSuccessAction = '[Option] Get Options Success'
}
export class GetOptions implements Action {
    public readonly type = EOptionActions.GetOptionsAction;
    constructor() {
    }
}

export class GetOptionsSuccess implements Action {
    public readonly type = EOptionActions.GetOptionsSuccessAction;
    constructor(public payload: IOption[]) { }
}

export class GetOption implements Action {
    public readonly type = EOptionActions.GetOptionAction;
    constructor(public payload: any) { }
}

export class GetOptionSuccess implements Action {
    public readonly type = EOptionActions.GetOptionSuccessAction;
    constructor(public payload: IOption) { }
}

export type OptionActions = GetOptions | GetOptionsSuccess | GetOption | GetOptionSuccess;
