import { SaveStage } from  '../../../../../../core/store/actions/stage.actions';
import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { RouterBack } from '../../../../../../core/store/actions/router.actions';
import { noop } from 'rxjs';
import { GetStages } from '../../../../../../core/store/actions/stage.actions';
import { selectStageList } from '../../../../../../core/store/selectors/stage.selectors';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../../../../core/store/state/app.state';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-stage-form-container',
  templateUrl: './create-stage-form-container.component.html'
})
export class CreateStageFormContainerComponent implements OnInit {

  stages$ = this.store.pipe(select(selectStageList));

  constructor(private store: Store<IAppState>, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.store.dispatch(new GetStages(this.activatedRoute.parent.snapshot.params['id']));
  }

  navigateToPreviousPage(enough: boolean) {
    if (enough) {
      this.store.dispatch(new RouterBack());
    } else {
      noop();
    }
  }

  onCreateStage(stage: IStage) {
    stage.codSubtype = this.activatedRoute.parent.snapshot.params['id'];
    this.store.dispatch(new SaveStage(stage));
  }

}
