import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { IState } from '../../../../../../shared/models/state/State.interface';
import { GetStates, GetStages, SaveState, DeleteState, UpdateStage, DeleteStage } from '../../../../../../core/store/actions/stage.actions';
import { ActivatedRoute } from '@angular/router';
import { GetLoading } from '../../../../../../core/store/actions/general.actions';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { selectLoading } from '../../../../../../core/store/selectors/general.selectors';
import { selectSelectedStage, selectStatesOfStage, selectStateList, selectStageList } from '../../../../../../core/store/selectors/stage.selectors';
import { IAppState } from '../../../../../../core/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stage-form-container',
  templateUrl: './stage-form-container.component.html',
  host: {
    class: 'container-customized'
  }
})
export class StageFormContainerComponent implements OnInit {

  stage$ = this.store.pipe(select(selectSelectedStage));
  stages$ = this.store.pipe(select(selectStageList));
  loading$ = this.store.pipe(select(selectLoading));
  states$ = this.store.pipe(select(selectStatesOfStage));
  statesSubtype$ = this.store.pipe(select(selectStateList));

  constructor(private store: Store<IAppState>, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.store.dispatch(new GetStates(this.activatedRoute.parent.snapshot.params['id']));
    this.store.dispatch(new GetStages(this.activatedRoute.parent.snapshot.params['id']));
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.stageSelected }));
  }

  onSaveState(state: IState) {
    this.store.dispatch(new SaveState(state));
  }

  onDeleteState(state: IState) {
    this.store.dispatch(new DeleteState(state));
  }

  onDeleteStage(stage: IStage) {
    this.store.dispatch(new DeleteStage(stage));
  }

  onUpdateStage(stage: IStage) {
    this.store.dispatch(new UpdateStage(stage));
  }

}
