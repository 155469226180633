import { IFieldStage } from '../../../shared/models/field/FieldStage.interface';
import { IField } from '../../../shared/models/field/Field.interface';

export interface IFieldState {
    selectedSubtypeFields: IField[];
    selectedTemplateFields: IFieldStage[];
}

export const initialFieldState: IFieldState = {
    selectedSubtypeFields: [],
    selectedTemplateFields: []
};
