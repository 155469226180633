import { noop } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState, GetLoading, selectLoading } from '../../../../../../../app/core';
import { ActivatedRoute } from '@angular/router';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { GetStages, UpdateStagesOrder, GetStates } from '../../../../../../core/store/actions/stage.actions';
import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { RouterGo } from '../../../../../../core/store/actions/router.actions';
import { selectStageList, selectStateList } from '../../../../../../core/store/selectors/stage.selectors';

@Component({
  selector: 'app-list-stage-container',
  templateUrl: './list-stage-container.component.html',
  host: {
    class: 'container-customized'
  }
})
export class ListStageContainerComponent implements OnInit {

  stages$ = this.store.pipe(select(selectStageList));
  states$ = this.store.pipe(select(selectStateList));
  loading$ = this.store.pipe(select(selectLoading));

  constructor(private store: Store<IAppState>, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.store.dispatch(new GetStages(this.activatedRoute.parent.snapshot.params['id']));
    this.store.dispatch(new GetStates(this.activatedRoute.parent.snapshot.params['id']));
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.stageList }));
  }

  updateStagesOrder(stages: IStage[]) {
    this.store.dispatch(new UpdateStagesOrder(stages));
  }

  navigateToStage(option: IStage) {
    this.store.dispatch(new RouterGo({
      path: [`etapa/${option.codStage}`], extras: {
        relativeTo: this.activatedRoute.parent
      }
    }));
  }

  navigateToCreateStage(enough: boolean) {
    if (enough) {
      this.store.dispatch(new RouterGo({
        path: [`etapas/crear`], extras: {
          relativeTo: this.activatedRoute.parent
        }
      }));
    } else {
      noop();
    }
  }
}
