import { IAppState } from '../../../core/store/state/app.state';
import { createSelector } from '@ngrx/store';
import { IContactState } from '../state/contact.state';

const contactState = (state: IAppState) => state.contact;

export const selectContactList = createSelector(
    contactState,
    (state: IContactState) => state.contacts
);

export const selectTaskContacstList = createSelector(
    contactState,
    (state: IContactState) =>  state.tasksContacts
);
