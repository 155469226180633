import { IProperty } from '../../../../../../shared/models/other/property/Property.interface';
import { STAGE_TEXT } from './../../../../../../shared/constants/text.constants';
import { PopupComponent } from '../../../../../../shared/components/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ALPHANUMERIC_UTF8_REGEX, ALPHANUMERIC_UTF8_PARAGRAPH_REGEX } from '../../../../../../shared/constants/regex.constants';
import { noop } from 'rxjs';
import { capitalizeString, reduceArrayToN, normalizeStatesToText } from '../../../../../../shared/constants/formats.constants';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { isComponentLoading } from '../../../../../../shared/constants/store.constants';
import { ILoading } from '../../../../../../shared/models/other/store/Loading.interface';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IState } from '../../../../../../shared/models/state/State.interface';
import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-stage-form',
  templateUrl: './stage-form.component.html',
  styleUrls: ['./stage-form.component.scss']
})
export class StageFormComponent implements OnInit, OnChanges {

  @Input()
  stage: IStage;

  @Input()
  stages: IStage[];

  @Input()
  states: IState[];

  @Input()
  statesSubtype: IState[];

  @Input()
  loading: ILoading;

  @Output()
  saveState$: EventEmitter<IState> = new EventEmitter();

  @Output()
  deleteState$: EventEmitter<IState> = new EventEmitter();

  @Output()
  updateStage$: EventEmitter<IStage> = new EventEmitter();

  @Output()
  deleteStage$: EventEmitter<IStage> = new EventEmitter();

  @ViewChild('optionsButton', { static: false })
  optionsButton: ElementRef;

  @ViewChild('pageTitle', { static: false })
  titleTag: ElementRef;

  stageFormGroup: FormGroup;

  property: IProperty = STAGE_TEXT;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.stageExists()) {
      this.initForms();
    } else {
      noop();
    }
  }

  initForms() {
    const maxlengthName = 40;
    const maxlengthDescription = 100;
    this.stageFormGroup = this.formBuilder.group({
      name: new FormControl(this.stage.name, [
        Validators.required,
        Validators.maxLength(maxlengthName),
        Validators.pattern(ALPHANUMERIC_UTF8_REGEX),
      ]),
      description: new FormControl(this.stage.description, [
        Validators.required,
        Validators.maxLength(maxlengthDescription),
        Validators.pattern(ALPHANUMERIC_UTF8_PARAGRAPH_REGEX),
      ])
    });
    this.stageFormGroup.disable();
  }

  onEmitSaveState(state: IState): void {
    this.saveState$.emit(state);
  }

  onEmitDeleteState(state: IState): void {
    this.deleteState$.emit(state);
  }

  onResetForm(): void {
    this.stageFormGroup.disable();
    this.stageFormGroup.get('name').setValue(this.stage.name);
    this.stageFormGroup.get('description').setValue(this.stage.description);
    this.titleTag.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }

  onEdit(): void {
    if (this.stageFormGroup.enabled) {
      this.onResetForm();
    } else {
      this.stageFormGroup.enable();
      this.optionsButton.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  onSubmit(): void {
    if (this.stageFormGroup.valid) {
      this.stageFormGroup.disable();
      this.updateStage$.emit({
        codStage: this.stage.codStage, // only this
        codSubtype: this.stage.codSubtype,
        description: this.stageFormGroup.get('description').value, // only this
        name: this.stageFormGroup.get('name').value, // only this
        position: this.stage.position,
        enabled: false,
        notify: false
      });
    } else {
      noop();
    }
  }

  onDelete(): void {
    const maxlengthArrayStates = 3;
    if (this.stageExists()) {
      const dialogRef = this.dialog.open(PopupComponent, {
        width: '320px',
        data: {
          title: '¡Espera!', message: `¿Estás seguro que deseas eliminar la etapa ${this.stage.name}?`,
          detail: `Recuerda que los estados asociados a esta etapa también serán eliminados:
           ${normalizeStatesToText(reduceArrayToN(this.states, maxlengthArrayStates))}`, action: false,
           singleButton: false
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteStage$.emit(this.stage);
        } else {
          noop();
        }
      });
    } else {
      noop();
    }
  }

  stageExists(): boolean {
    return this.stage ? true : false;
  }

  isReadOnly(type: string): boolean {
    return (this.stageExists()) ? this.stageFormGroup.disabled : true;
  }

  getStageProperty(property: string): string | boolean | number {
    if (property === 'name') {
      return (this.stageExists() ? this.stage.name : 'Cargando etapa...');
    } else if (property === 'pos') {
      return (this.stageExists() ? this.stage.position : 0);
    }
    return null;
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.stageSelected);
  }

  capitalizeString(name: string): string {
    return capitalizeString(name);
  }
}
