import { ISubtypeState } from '../../store/state/subtype.state';
import { SubtypeActions, ESubtypeActions } from '../../store/actions/subtype.actions';
import { initialSubtypeState } from '../../store/state/subtype.state';

export function subtypeReducer(
    state = initialSubtypeState,
    action: SubtypeActions
): ISubtypeState {
    switch (action.type) {
        case ESubtypeActions.GetSubtypesSuccessAction: {
            return {
                ...state,
                subtypes: action.payload
            };
        }
        case ESubtypeActions.GetSubtypeSuccessAction: {
            return {
                ...state,
                selectedSubtype: action.payload
            };
        }
        default:
            return state;
    }
}
