import { IAppState } from '../../../core/store/state/app.state';
import { createSelector } from '@ngrx/store';
import { IAnswerableState } from '../state/answerable.state';

const answerableState = (state: IAppState) => state.answerable;

export const selectAnswerableList = createSelector(
    answerableState,
    (state: IAnswerableState) => state.answerables
);
