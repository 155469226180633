import { IOptionState } from '../../../core/store/state/option.state';
import { IAppState } from '../../../core/store/state/app.state';
import { createSelector } from '@ngrx/store';

const optionState = (state: IAppState) => state.option;

export const selectOptionList = createSelector(
    optionState,
    (state: IOptionState) => state.options
);

export const selectSelectedOption = createSelector(
    optionState,
    (state: IOptionState) => state.selectedOption
);
