<section class="stage">
    <app-loading *ngIf="componentIsLoading()"></app-loading>
    <div *ngIf="!componentIsLoading()">
        <h3><img src="{{property.option.iconActive}}" alt="Icon"> {{property.label.title}}</h3>
        <p #pageTitle>{{property.label.selected}}</p>
        <app-info-box *ngIf="states && states.length <= 0" type="warning"
            content="Recuerda que para el envío de las notificaciones de esta etapa a los clientes, debes asociar un estado.">
        </app-info-box>
        <app-info-box *ngIf="stage && !stage.notify && states && states.length > 0" type="warning"
            content="Recuerda que para el envío de las notificaciones de esta etapa, debes habilitar la opción de notificaciones.">
        </app-info-box>
        <main class="stage-body">
            <section class="box stage">
                <header class="box-header">
                    <h5><em class="fas fa-check-circle"></em> {{capitalizeString(stage?.name)}}</h5>
                    <div class="box-options">
                        <a id="edit-stage-action" (click)="onEdit()">
                            <em class="fas fa-pencil-alt animated-scale"></em></a>
                        <a id="delete-stage-action" (click)="onDelete()">
                            <em class="fas fa-trash-alt animated-scale"></em></a>
                    </div>
                </header>
                <main class="box-body">
                    <form *ngIf="stageFormGroup" [formGroup]="stageFormGroup">
                        <mat-form-field appearance="fill">
                            <mat-label>Nombre</mat-label>
                            <input matInput formControlName="name" [value]="stageFormGroup.value.name" />
                            <mat-hint align="end">Nombre de la etapa
                            </mat-hint>
                        </mat-form-field>
                        <div *ngIf="stageFormGroup.controls.name.errors">
                            <app-form-error-box *ngIf="stageFormGroup.controls.name.errors.pattern"
                                message="Verifique que el nombre sea alfanumérico">
                            </app-form-error-box>
                            <app-form-error-box *ngIf="stageFormGroup.controls.name.errors.required"
                                message="Por favor escriba un nombre">
                            </app-form-error-box>
                            <app-form-error-box *ngIf="stageFormGroup.controls.name.errors.maxlength"
                                message="Por favor no exceda el límite de caracteres. Límite:
                                {{stageFormGroup.controls.name.errors.maxlength.actualLength}} /
                                {{stageFormGroup.controls.name.errors.maxlength.requiredLength}}">
                            </app-form-error-box>
                        </div>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea matInput formControlName="description" [value]="stageFormGroup.value.description"
                                rows="3"></textarea>
                            <mat-hint align="end">Descripción de la etapa
                            </mat-hint>
                        </mat-form-field>
                        <div *ngIf="stageFormGroup.controls.description.errors">
                            <app-form-error-box *ngIf="stageFormGroup.controls.description.errors.pattern"
                                message="Verifique que la descripción sea alfanumérica">
                            </app-form-error-box>
                            <app-form-error-box *ngIf="stageFormGroup.controls.description.errors.required"
                                message="Por favor escriba una descripción">
                            </app-form-error-box>
                            <app-form-error-box *ngIf="stageFormGroup.controls.description.errors.maxlength"
                                message="Por favor no exceda el límite de caracteres. Límite:
                                {{stageFormGroup.controls.description.errors.maxlength.actualLength}} /
                                {{stageFormGroup.controls.description.errors.maxlength.requiredLength}}">
                            </app-form-error-box>
                        </div>
                    </form>
                </main>
                <footer class="box-footer">
                    Notificaciones
                    <app-switch-stage-form-container [stage]="stage">
                    </app-switch-stage-form-container>
                </footer>
            </section>
            <app-state-form class="state" [states]="states" [stage]="stage" [statesSubtype]="statesSubtype"
                [stages]="stages" (saveState$)="onEmitSaveState($event)" (deleteState$)="onEmitDeleteState($event)">
            </app-state-form>
        </main>
        <div class="w-100 text-center" *ngIf="stageFormGroup && stageFormGroup.enabled">
            <button (click)="onResetForm()" class="btn btn-secondary">Cancelar</button>
            <button (click)="onSubmit()" class="btn btn-primary">Guardar cambios</button>
        </div>
        <p #optionsButton></p>
    </div>
</section>