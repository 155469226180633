import { TemplateActions, ETemplateActions } from '../../store/actions/template.actions';
import { initialTemplateState, ITemplateState } from '../../store/state/template.state';

export function templateReducer(
    state = initialTemplateState,
    action: TemplateActions
): ITemplateState {
    switch (action.type) {
        case ETemplateActions.GetTemplatesSuccessAction: {
            return {
                ...state,
                templates: action.payload
            };
        }
        case ETemplateActions.UpdateTemplateSuccessAction:
        case ETemplateActions.GetTemplateSuccessAction: {
            return {
                ...state,
                selectedTemplate: action.payload
            };
        }
        default:
            return state;
    }
}
