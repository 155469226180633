import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../../../../../../../app/shared/components/popup/popup.component';

@Component({
  selector: 'app-switch-stage-form',
  templateUrl: './switch-stage-form.component.html',
  styleUrls: ['./switch-stage-form.component.scss']
})
export class SwitchStageFormComponent implements OnInit, OnChanges {

  @Input()
  stage: IStage;

  @Output()
  stageUpdateNotification$: EventEmitter<IStage> = new EventEmitter();

  checked: boolean;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.checked = this.initSwitchEnabled();
  }

  onChange(event: MatSlideToggleChange): void {
    this.checked = event.checked;
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '320px',
      data: {
        title: '¡Espera!', message: `${this.getMessage(event.checked)}`, detail: this.getDetail(event.checked),
        action: false,
        singleButton: false
      }
      
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stage.notify = !this.stage.notify;
        this.stageUpdateNotification$.emit(this.stage);
      } else {
        this.checked = !this.checked;
      }
    });
  }

  getDetail(checked: boolean): string {
    if (!checked) {
      return `Recuerda que deshabilitando esta opción, los clientes no serán notificados en la etapa
        ${this.stage.name}`;
    } else {
      return `Recuerda que habilitando esta opción, los clientes serán notificados para la etapa
        ${this.stage.name} dependiendo de los canales habilitados en la plantilla`;
    }
  }

  getMessage(checked: boolean): string {
    if (!checked) {
      return `¿Estás seguro que quieres deshabilitar las notificaciones para la etapa ${this.stage.name}?`;
    } else {
      return `¿Estás seguro que quieres habilitar las notificaciones para la etapa ${this.stage.name}?`;
    }
  }

  initSwitchEnabled(): boolean {
    return (this.stage) ? this.stage.notify : false;
  }

}
