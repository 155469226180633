import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../shared/models/other/http/Response.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  constructor(private http: HttpClient) { }

  getFolderByEmailFromToken(): Observable<IResponse> {
    return this.http.get<IResponse>(`${environment.apiEndPoint}/folders/email`);
  }
}
