<section class="product-card">
    <h3><img src="{{property.option.iconActive}}" alt="Icon"> {{property.label.title}}</h3>
    <p>{{property.label.unselected}}</p>
    <app-loading *ngIf="componentIsLoading()"></app-loading>
    <main class="product-card-main" *ngIf="!componentIsLoading()">
        <div class="box half" *ngFor="let su of subtypes">
            <header class="box-header">
                <img src="/assets/icons/on/{{su.folder.icon}}" alt="ícono " />
                <h5>{{su.folder.name}}</h5>
            </header>
            <main class="box-body" *ngIf="su.subtypeList">
                <ul class="subtype-list">
                    <li *ngFor="let subtype of su.subtypeList" (click)="navigateToSubtype(subtype.codSubtype.toString())" >
                        {{subtype.name}}</li>
                </ul>
            </main>
        </div>
    </main>
</section>
