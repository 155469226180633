import { MonoTypeOperatorFunction, OperatorFunction } from 'rxjs';
import { Action } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { RouteNavigate } from '../../core/store/actions/router.actions';
import { ERouterActions } from '../../core/store/actions/router.actions';

export function isRoute(route: string | string[] | RegExp) {
    return (action: Action) => {
        const isRouteAction = action.type === ERouterActions.RouterNavigateAction;
        if (isRouteAction) {
            const routeAction = action as RouteNavigate;
            const routePath = routeAction.payload.path;
            if (Array.isArray(route)) {
                return route.indexOf(routePath) > -1;
            } else if (route instanceof RegExp) {
                return route.test(routePath);
            } else {
                return routePath === route;
            }
        }
        return isRouteAction;
    };
}

export function ofRoute(route: string | string[] | RegExp): MonoTypeOperatorFunction<RouteNavigate> {
    return filter<RouteNavigate>(isRoute(route));
}

export function mapToParam<T>(key: string): OperatorFunction<RouteNavigate, T> {
    return map<RouteNavigate, T>(action => action.payload.params[key]);
}

export function mapToQueryParam<T>(key: string): OperatorFunction<RouteNavigate, T> {
    return map<RouteNavigate, T>(action => action.payload.queryParams[key]);
}

export function mapToData<T>(key: string): OperatorFunction<RouteNavigate, T> {
    return map<RouteNavigate, T>(action => action.payload.data[key]);
}
