<div class="product-list">
    <app-loading *ngIf="componentIsLoading()" class="text-center"></app-loading>
    <mat-form-field *ngIf="!componentIsLoading()">
        <mat-label>Selecciona el tipo de solicitud</mat-label>
        <mat-select [(value)]="selected" (selectionChange)="navigateToSubtype($event.value)">
            <mat-optgroup *ngFor="let sub of subtypes" [label]="sub.folder.name">
                <mat-option #matOption *ngFor="let subtype of sub.subtypeList"
                    [value]="sub.folder.codFolder +'/' + subtype.codSubtype">
                    {{subtype.name}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</div>
