import { IStageState } from '../../store/state/stage.state';
import { IAppState } from '../../store/state/app.state';
import { createSelector } from '@ngrx/store';

const stageState = (state: IAppState) => state.stage;

export const selectStageList = createSelector(
    stageState,
    (state: IStageState) => state.stages
);

export const selectSelectedStage = createSelector(
    stageState,
    (state: IStageState) => state.selectedStage
);

export const selectStateList = createSelector(
    stageState,
    (state: IStageState) => state.states
);

export const selectStatesOfStage = createSelector(
    stageState,
    (state: IStageState) => state.statesOfStage
);
