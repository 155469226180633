/* Angular */
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

/* This */
import { AppMaterialModule } from "../app/app-material.module";
import { AppRoutingModule } from "../app/app-routing.module";
import { AppComponent } from "../app/app.component";

/* Core */
import { UnauthorizedInterceptor } from './core/security/unauthorized-interceptor';

/* Misc */
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { environment } from "../environments/environment";

/* Dashboard */
import { DashboardModule } from "./modules/dashboard/dashboard.module";

/* Store */
import { StageEffects } from "./core/store/effects/stage.effects";
import { TemplateEffects } from "./core/store/effects/template.effects";
import { GeneralEffects } from "./core/store/effects/general.effects";
import { SubtypeEffects } from "./core/store/effects/subtype.effects";
import { appReducers } from "./core/store/reducers/app.reducers";
import { RouterEffects } from "./core/store/effects/router.effects";
import { FieldEffects } from "./core/store/effects/field.effects";
import { OptionEffects } from "./core/store/effects/option.effects";
import { TaskEffects } from "./core/store/effects/task.effects";
import { AnswerableEffects } from "./core/store/effects/answerable.effects";
import { ContactEffects } from "./core/store/effects/contact.effects";

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { 
  MsalGuard, 
  MsalInterceptor, 
  MsalBroadcastService, 
  MsalInterceptorConfiguration, 
  MsalModule, 
  MsalService, 
  MSAL_GUARD_CONFIG, 
  MSAL_INSTANCE, 
  MSAL_INTERCEPTOR_CONFIG, 
  MsalGuardConfiguration, 
  MsalRedirectComponent 
} from '@azure/msal-angular';
import { 
  IPublicClientApplication, 
  PublicClientApplication, 
  InteractionType, 
  BrowserCacheLocation, 
  LogLevel 
} from '@azure/msal-browser';
import { HeadersService } from './core/interceptors/headers.service';
import { DatePipe } from '@angular/common';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureActiveDirectory.clientId,
      authority: `https://login.microsoftonline.com/${environment.azureActiveDirectory.tenantId}`,
      redirectUri: environment.azureActiveDirectory.redirectUri,
      postLogoutRedirectUri: `${environment.host}/login`
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
    "user.read"
  ]);
  if (environment.enableMsal === "true") {
    protectedResourceMap.set(environment.apiProtected, [
      environment.azureActiveDirectory.clientId + "/.default"
    ]);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        'user.read',
        'openid',
        'profile',
      ]
    },
    loginFailedRoute: "login"
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    StoreModule.forRoot(appReducers,
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([SubtypeEffects, GeneralEffects, TaskEffects, OptionEffects, TemplateEffects,
      StageEffects, FieldEffects, RouterEffects, AnswerableEffects, ContactEffects]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    FontAwesomeModule,
    HttpClientModule,
    AppMaterialModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    BrowserAnimationsModule,
    MsalModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: msalGuardConfigFactory
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: msalInterceptorConfigFactory
      },
      MsalGuard,
      MsalBroadcastService,
      MsalService,
      { provide: HTTP_INTERCEPTORS, useClass: HeadersService, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
      DatePipe,
    ]
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
