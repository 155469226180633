import { Component, Input, OnInit, OnChanges, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { IProperty } from '../../../../../../shared/models/other/property/Property.interface';
import { ILoading } from '../../../../../../shared/models/other/store/Loading.interface';
import { ITask } from '../../../../../../shared//models/task/Task.interface';
import { IAnswerable } from '../../../../../../shared//models/answerable/Answerable.interface';
import { isComponentLoading } from '../../../../../../shared/constants/store.constants';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { TASK_FORM_TEXT } from './../../../../../../shared/constants/text.constants';
import { IStage } from '../../../../../../shared//models/stage/Stage.interface';
import { ActivatedRoute } from '@angular/router';
import { IContact } from '../../../../../../shared/models/contact/Contact.interface';
import { ITaskContacts } from '../../../../../../shared/models/taskContacts/TaskContacts.interface';
import { TaskFormComponent } from '../../components/task-form/task-form.component';
import { IState } from '../../../../../../shared/models/state/State.interface';

@Component({
  selector: 'app-list-task-form',
  templateUrl: './list-task-form.component.html',
  styleUrls: ['./list-task-form.component.scss']
})
export class ListTaskFormComponent implements OnInit, OnChanges {

  @Input() tasks: ITask[];
  @Input() stages: IStage[];
  @Input() loading: ILoading;
  @Input() answerables: IAnswerable[];
  @Input() contacts: IContact[];
  @Input() taskContacts: ITaskContacts[];
  @Input() states: IState[];

  @Output() deleteTask$: EventEmitter<ITask> = new EventEmitter();
  @Output() createAnswerable$: EventEmitter<string> = new EventEmitter();

  @ViewChildren(TaskFormComponent) formEditTask: QueryList<TaskFormComponent>;

  stage: IStage;
  myTasks: any;
  property: IProperty = TASK_FORM_TEXT;
  public createTask: boolean;
  state: IState;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.createTask = false;
    this.stage = this.filterOfCodStage(this.stages)[0];
    this.myTasks = this.filterOfCodStage(this.tasks);
  }

  ngOnChanges() {
    this.stage = this.filterOfCodStage(this.stages)[0];
    this.myTasks = this.filterOfCodStage(this.tasks);
  }

  initCreateTask() {
    this.createTask = true;
  }

  cancelCreate(cancel: boolean) {
    this.createTask = cancel;
  }

  componentIsLoading(): boolean {
    return (isComponentLoading(this.loading, ELoadingContent.stageList));
  }

  areThereTasks(): boolean {
    return (this.filterOfCodStage(this.tasks) && this.filterOfCodStage(this.tasks).length > 0) ? true : false;
  }

  filterOfCodStage(array: any[]): any[] {
    if (array !== undefined && array !== null ) {
      return array.filter(
        f => f.codStage === +this.activatedRoute.snapshot.url.join('/').split('/')[1]);
    } else {
      return [];
    }
  }

  filterOfCodeTask(array: any[], codTask: number): any[] {
    if (array !== undefined && array !== null ) {
      return array.filter( f => f.codTask === codTask );
    } else {
      return [];
    }
  }

  public edit(task: ITask) {
    const result = this.formEditTask.find( e => e.task === task );
    result.onEdit();
  }

   public delete(task: ITask) {
     const result = this.formEditTask.find( e => e.task === task );
     result.onDelete();
  }

   onEmitDeleteTask(task: ITask): void {
    this.deleteTask$.emit(task);
  }

  onEmitCreateAnswerable(answerable: string): void {
    this.createAnswerable$.emit(answerable);
  }
}
