import { ITask } from '../../../shared/models/task/Task.interface';
import { Action } from '@ngrx/store';

export enum ETaskActions {
    GetTasksAction = '[Task] Get Tasks',
    GetTasksSuccessAction = '[Task] Get Tasks Success',
    GetTasksOfStageAction = '[Task] Get Tasks Of Stage',
    GetTasksOfStageSuccessAction = '[Task] Get Tasks Of Stage Success',
    DeleteTaskSuccessAction = '[Task] Delete Task Success',
    DeleteTaskAction= '[Task] Delete Task',
    UpdateTaskSuccessAction = '[Task] Update Task Success',
    UpdateTaskAction= '[Task] Update Task',
    CreateTaskSuccessAction = '[Task] Create Task Success',
    CreateTaskAction= '[Task] Create Task',
}

export class GetTasks implements Action {
    public readonly type = ETaskActions.GetTasksAction;
    constructor(public payload: string) { }
}

export class GetTasksOfStage implements Action {
    public readonly type = ETaskActions.GetTasksOfStageAction;
    constructor(public payload: string) { }
}
export class GetTasksOfStageSuccess implements Action {
    public readonly type = ETaskActions.GetTasksSuccessAction;
    constructor(public payload: ITask[]) { }
}

export class GetTasksSuccess implements Action {
    public readonly type = ETaskActions.GetTasksOfStageSuccessAction;
    constructor(public payload: ITask[]) { }
}

export class DeleteTask implements Action {
    public readonly type = ETaskActions.DeleteTaskAction;
    constructor(public payload: ITask) { }
}

export class DeleteTaskSuccess implements Action {
    public readonly type = ETaskActions.DeleteTaskSuccessAction;
    constructor(public payload: void) {
    }
}

export class UpdateTask implements Action {
    public readonly type = ETaskActions.UpdateTaskAction;
    constructor(public id: number, public payload: ITask) { }
}

export class UpdateTaskSuccess implements Action {
    public readonly type = ETaskActions.UpdateTaskSuccessAction;
    constructor(public payload: void) {
    }
}

export class CreateTask implements Action {
    public readonly type = ETaskActions.CreateTaskAction;
    constructor(public payload: ITask) { }
}

export class CreateTaskSuccess implements Action {
    public readonly type = ETaskActions.CreateTaskSuccessAction;
    constructor(public payload: void) {
    }
}

export type TaskActions = GetTasks | GetTasksSuccess | GetTasksOfStage | GetTasksOfStageSuccess | DeleteTask | DeleteTaskSuccess;
