import { selectLoading } from '../../../../../../core/store/selectors/general.selectors';
import { selectStageList } from '../../../../../../core/store/selectors/stage.selectors';
import {  selectTaskList} from '../../../../../../core/store/selectors/task.selectors';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { GetLoading } from '../../../../../../core/store/actions/general.actions';
import { GetStages } from '../../../../../../core/store/actions/stage.actions';
import { IAppState } from '../../../../../../core/store/state/app.state';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { GetTasks } from '../../../../../../../app/core/store/actions/task.actions';
import { RouterGo } from '../../../../../../core/store/actions/router.actions';
import { IStage } from '../../../../../../../app/shared/models/stage/Stage.interface';

@Component({
  selector: 'app-list-task-container',
  templateUrl: './list-task-container.component.html'
})
export class ListTaskContainerComponent implements OnInit {

  stages$ = this.store.pipe(select(selectStageList));
  tasks$ = this.store.pipe(select(selectTaskList));
  loading$ = this.store.pipe(select(selectLoading));

  constructor(private store: Store<IAppState>, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.store.dispatch(new GetStages(this.activatedRoute.parent.snapshot.params['id']));
    this.store.dispatch(new GetTasks(this.activatedRoute.parent.snapshot.params['id']));
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.stageList }));
  }

  navigateToTask(option: IStage) {
    this.store.dispatch(new RouterGo({
      path: [`tarea/${option.codStage}`], extras: {
        relativeTo: this.activatedRoute.parent
      }
    }));
  }

}
