import { selectLoading } from '../../../../core/store/selectors/general.selectors';
import { RouterGo } from '../../../../core/store/actions/router.actions';
import { ELoadingContent } from '../../../../shared/models/other/store/Loading.enum';
import { GetLoading } from '../../../../core/store/actions/general.actions';
import { GetSubtypes } from '../../../../core/store/actions/subtype.actions';
import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { IAppState } from '../../../../core/store/state/app.state';
import { selectSubtypeList, selectSelectedSubtype } from '../../../../../app/core';

@Component({
  selector: 'app-product-list-container',
  templateUrl: './product-list-container.component.html'
})
export class ProductListContainerComponent implements OnInit {

  subtypes$ = this.store.pipe(select(selectSubtypeList));
  subtype$ = this.store.pipe(select(selectSelectedSubtype));
  loading$ = this.store.pipe(select(selectLoading));

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
    this.store.dispatch(new GetSubtypes());
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.subtypeList }));
  }

  navigateToSubtype(subtype: string) {
    this.store.dispatch(new RouterGo({ path: [`producto/${subtype}`] }));
  }

}
