<section class="stage">
    <h3><img src="assets/icons/on/check.svg" alt="Etapa"> Crear nueva etapa</h3>
    <app-info-box
        content="Recuerda que para el envío de las notificaciones de esta nueva etapa, debes tener asociado un estado y habilitada la opción de notificaciones. En caso de que no se presenten estas dos condiciones, la etapa se guardará pero no se notificará.">
    </app-info-box>
    <main class="stage-body">
        <section class="box stage">
            <header class="box-header">
                <h5><em class="fas fa-check-circle"></em> Nueva etapa</h5>
            </header>
            <main class="box-body">
                <form *ngIf="stageFormGroup" [formGroup]="stageFormGroup">
                    <mat-form-field appearance="fill">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name" [value]="stageFormGroup.value.name" />
                        <mat-hint align="end">Nombre de la etapa
                        </mat-hint>
                    </mat-form-field>
                    <div *ngIf="stageFormGroup.controls.name.errors && submitted">
                        <app-form-error-box *ngIf="stageFormGroup.controls.name.errors.pattern"
                            message="Verifique que el nombre sea alfanumérico">
                        </app-form-error-box>
                        <app-form-error-box *ngIf="stageFormGroup.controls.name.errors.required"
                            message="Por favor escriba un nombre">
                        </app-form-error-box>
                        <app-form-error-box *ngIf="stageFormGroup.controls.name.errors.maxlength" 
                            message="Por favor no exceda el límite de caracteres. Límite:
                            {{stageFormGroup.controls.name.errors.maxlength.actualLength}} /
                            {{stageFormGroup.controls.name.errors.maxlength.requiredLength}}">
                        </app-form-error-box>
                    </div>
                    <mat-form-field appearance="fill">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput formControlName="description" [value]="stageFormGroup.value.description"
                            rows="3"></textarea>
                        <mat-hint align="end">Descripción de la etapa
                        </mat-hint>
                    </mat-form-field>
                    <div *ngIf="stageFormGroup.controls.description.errors && submitted">
                        <app-form-error-box *ngIf="stageFormGroup.controls.description.errors.pattern"
                            message="Verifique que la descripción sea alfanumérica">
                        </app-form-error-box>
                        <app-form-error-box *ngIf="stageFormGroup.controls.description.errors.required"
                            message="Por favor escriba una descripción">
                        </app-form-error-box>
                        <app-form-error-box *ngIf="stageFormGroup.controls.description.errors.maxlength" 
                            message="Por favor no exceda el límite de caracteres. Límite:
                            {{stageFormGroup.controls.description.errors.maxlength.actualLength}} /
                            {{stageFormGroup.controls.description.errors.maxlength.requiredLength}}">
                        </app-form-error-box>
                    </div>
                </form>
            </main>
        </section>
        <div class="option-buttons">
            <button class="btn btn-secondary" type="button" (click)="onCancel()">Cancelar</button>
            <button class="btn btn-primary" type="button" (click)="onSubmit()">Guardar cambios</button>
        </div>
    </main>
</section>