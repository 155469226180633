/* Angular */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

/* Common components */
import { SafePipe } from './../pipes/safe.pipe';
import { LoadingComponent } from './loading/loading.component';
import { PopupComponent } from './popup/popup.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FormErrorBoxComponent } from './form-error-box/form-error-box.component';
import { NoContentComponent } from './no-content/no-content.component';
import { InfoBoxComponent } from './info-box/info-box.component';


import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ServiceUnavailableComponent } from './service-unavailable/service-unavailable.component';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';





@NgModule({
    imports: [
        CommonModule,
        BcInputModule,
        ReactiveFormsModule,
        BcIconModule.forRoot({
            path: "https://library-sdb.apps.bancolombia.com/bds/6.17.5"
        }),
    ],
    declarations: [
        LoadingComponent,
        PopupComponent,
        NotFoundComponent,
        FormErrorBoxComponent,
        NoContentComponent,
        SafePipe,
        InfoBoxComponent,
        UnauthorizedComponent,
        ServiceUnavailableComponent,
        PopupComponent
    ],
    exports: [
        LoadingComponent,
        PopupComponent,
        NotFoundComponent,
        FormErrorBoxComponent,
        NoContentComponent,
        SafePipe,
        InfoBoxComponent,
        UnauthorizedComponent,
        ServiceUnavailableComponent
    ]
})

export class SharedModule { }
