import { IFieldState } from '../../../core/store/state/field.state';
import { IAppState } from '../../../core/store/state/app.state';
import { createSelector } from '@ngrx/store';

const fieldState = (state: IAppState) => state.field;

export const selectSelectedSubtypeFields = createSelector(
    fieldState,
    (state: IFieldState) => state.selectedSubtypeFields
);

export const selectSelectedTemplateFields = createSelector(
    fieldState,
    (state: IFieldState) => state.selectedTemplateFields
);
