import { IStageState } from '../../../core/store/state/stage.state';
import { StageActions, EStageActions } from '../../../core/store/actions/stage.actions';
import { initialStageState } from '../../../core/store/state/stage.state';

export function stageReducer(
    state = initialStageState,
    action: StageActions
): IStageState {
    switch (action.type) {
        case EStageActions.UpdateStagesOrderSuccessAction:
        case EStageActions.GetStagesSuccessAction: {
            return {
                ...state,
                stages: action.payload
            };
        }
        case EStageActions.UpdateStageSuccessAction:
        case EStageActions.SaveStageSuccessAction:
        case EStageActions.GetStageSuccessAction: {
            return {
                ...state,
                selectedStage: action.payload
            };
        }
        case EStageActions.GetStatesSuccessAction: {
            return {
                ...state,
                states: action.payload
            };
        }
        case EStageActions.DeleteStateSuccessAction:
        case EStageActions.SaveStateSuccessAction:
        case EStageActions.GetStateSuccessAction: {
            return {
                ...state,
                statesOfStage: action.payload
            };
        }
        default:
            return state;
    }
}
