<div class="box box-info" *ngIf="visible">
    <main class="box-body">
        <section id="info-component">
            <article id="info-body">
                <em [class]="getIcon()"></em>
                <main id="info-message">
                    <p>{{content}}</p>
                </main>
            </article>
            <a class="close animated-scale" type="button" (click)="onSwitchVisible()">
                <em class="fas fa-times fa-lg"></em>
            </a>
        </section>
    </main>
</div>