import { IContact } from '../../../shared/models/contact/Contact.interface';
import { ITaskContacts } from '../../../shared/models/taskContacts/TaskContacts.interface';


export interface IContactState {
    contacts: IContact[];
    tasksContacts: ITaskContacts[];
}

export const initialContactState: IContactState = {
    contacts: [],
    tasksContacts: [],
};
