import { Component, OnInit, Input } from '@angular/core';
import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../../../app/core';
import { UpdateStageNotification } from '../../../../../../core/store/actions/stage.actions';

@Component({
  selector: 'app-switch-stage-form-container',
  templateUrl: './switch-stage-form-container.component.html'
})
export class SwitchStageFormContainerComponent implements OnInit {

  @Input()
  type: string;

  @Input()
  stage: IStage;

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
  }

  updateStageNotification(stage: IStage) {
    this.store.dispatch(new UpdateStageNotification(stage));
  }
}
