import { IOption } from '../../../shared/models/option/Option.interface';

export interface IOptionState {
    options: IOption[];
    selectedOption: IOption;
}

export const initialOptionState: IOptionState = {
    options: [],
    selectedOption: null
};
