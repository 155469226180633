import { ActivatedRoute } from '@angular/router';
import { RouterGo, RouterBack } from './../actions/router.actions';
import { StageService } from '../../../core/services/stage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IResponse } from '../../../shared/models/other/http/Response.interface';
import { RESET_LOADING_BODY } from '../../../shared/constants/store.constants';
import { StateService } from '../../../core/services/state.service';
import { GetLoading, GetLoadingSuccess } from '../../../core/store/actions/general.actions';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { IAppState } from '../../../core/store/state/app.state';
import {
  ofRoute, mapToParam
} from '../../../shared/pipes/router-store.pipe';
import {
  GetStageSuccess,
  GetStatesSuccess,
  GetStateSuccess,
  GetStates,
  SaveState,
  SaveStateSuccess,
  DeleteState,
  DeleteStateSuccess,
  DeleteStage,
  DeleteStageSuccess,
  SaveStage,
  SaveStageSuccess,
  UpdateStage,
  UpdateStageSuccess,
  UpdateStagesOrder,
  UpdateStagesOrderSuccess,
  UpdateStageNotification,
  UpdateStageNotificationSuccess,
  GetStagesSuccess,
  EStageActions,
  GetStages
} from '../../../core/store/actions/stage.actions';

@Injectable()
export class StageEffects {

  stageRouted$ = createEffect(() => this.actions$.pipe(
    ofRoute('etapa/:id'),
    mapToParam<string>('id'),
    switchMap(id => this.stageService.getStageById(id)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      return of(new GetStageSuccess(response.data[0]));
    }
    )
  ));

  stateRouted$ = createEffect(() => this.actions$.pipe(
    ofRoute('etapa/:id'),
    mapToParam<string>('id'),
    switchMap(id => this.stateService.getStatesOfStage(id)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      return of(new GetStateSuccess(response.data));
    }
    )
  ));

  saveState$ = createEffect(() => this.actions$.pipe(
    ofType<SaveState>(EStageActions.SaveStateAction),
    switchMap(payload => this.stateService.saveState(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! El estado se asoció a esta etapa`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      this.store.dispatch(new GetStates(response.data[0].codSubtype));
      return of(new SaveStateSuccess(response.data));
    })
  ));

  saveStage$ = createEffect(() => this.actions$.pipe(
    ofType<SaveStage>(EStageActions.SaveStageAction),
    switchMap(payload => this.stageService.saveStage(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se creó la etapa ${response.data[0].name}`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      this.store.dispatch(new RouterGo({
        path: [`producto/${response.data[0].codSubtype}/etapa/${response.data[0].codStage}`]
      }));
      return of(new SaveStageSuccess(response.data[0]));
    })
  ));

  deleteState$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteState>(EStageActions.DeleteStateAction),
    switchMap(payload => this.stateService.deleteState(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! El estado se eliminó correctamente`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      return of(new DeleteStateSuccess(response.data));
    })
  ));

  deleteStage$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteStage>(EStageActions.DeleteStageAction),
    switchMap(payload => this.stageService.deleteStage(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se eliminó la etapa correctamente`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      this.store.dispatch(new RouterBack());
      return of(new DeleteStageSuccess(response.data));
    })
  ));

  getStages$ = createEffect(() => this.actions$.pipe(
    ofType<GetStages>(EStageActions.GetStagesAction),
    switchMap(action => this.stageService.getStagesBySubtypeId(action.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoading(RESET_LOADING_BODY));
      return of(new GetStagesSuccess(response.data));
    })
  ));

  getStates$ = createEffect(() => this.actions$.pipe(
    ofType<GetStates>(EStageActions.GetStatesAction),
    switchMap(action => {
      return this.stateService.getStatesOfSubtype(action.payload);
    }),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoading(RESET_LOADING_BODY));
      return of(new GetStatesSuccess(response.data));
    })
  ));

  updateStagesOrder$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateStagesOrder>(EStageActions.UpdateStagesOrderAction),
    switchMap(action => this.stageService.reorderStages(action.stages)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoading(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se actualizó el orden de las etapas`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      return of(new UpdateStagesOrderSuccess(response.data));
    })
  ));

  updateStage$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateStage>(EStageActions.UpdateStageAction),
    switchMap(action => this.stageService.updateStage(action.payload)),
    switchMap(response => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se actualizó la etapa ${response.data[0].name}`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      return of(new UpdateStageSuccess(response.data[0]));
    })
  ));

  updateStageNotification$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateStageNotification>(EStageActions.UpdateStageNotificationAction),
    switchMap(payload => this.stageService.updateStageNotification(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se actualizaron las notificaciones para la etapa ${response.data[0].name}`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      return of(new UpdateStageNotificationSuccess(response.data[0]));
    })
  ));

  constructor(
    private stageService: StageService,
    private stateService: StateService,
    private actions$: Actions,
    private store: Store<IAppState>,
    private notification: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) { }
}
