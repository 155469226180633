import { IResponse } from '../../shared/models/other/http/Response.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OPTIONS_CONST } from '../../shared/constants/options.constants';

@Injectable({
  providedIn: 'root'
})
export class OptionService {

  constructor() { }

  getAllOptions(): Observable<IResponse> {
    return of(OPTIONS_CONST);
  }
}
