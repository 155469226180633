import { ALPHANUMERIC_UTF8_REGEX, ALPHANUMERIC_UTF8_PARAGRAPH_REGEX } from '../../../../../../shared/constants/regex.constants';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { noop } from 'rxjs';
import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-create-stage-form',
  templateUrl: './create-stage-form.component.html',
  styleUrls: ['./create-stage-form.component.scss']
})
export class CreateStageFormComponent implements OnInit, OnChanges {

  @Input()
  stages: IStage[];

  @Output()
  goBackOnRouter$: EventEmitter<boolean> = new EventEmitter();

  @Output()
  createStage$: EventEmitter<IStage> = new EventEmitter();

  stageFormGroup: FormGroup;
  submitted: boolean;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForms();
    this.submitted = false;
  }

  ngOnChanges() {
    const maxlengthStages = 7;
    if (this.stages) {
      if (this.stages.length > maxlengthStages) {
        this.goBackOnRouter$.emit(true);
      } else {
        noop();
      }
    } else {
      noop();
    }
  }

  initForms() {
    const maxlengthName = 40;
    const maxlengthDescription = 100;
    this.stageFormGroup = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(maxlengthName),
        Validators.pattern(ALPHANUMERIC_UTF8_REGEX),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(maxlengthDescription),
        Validators.pattern(ALPHANUMERIC_UTF8_PARAGRAPH_REGEX),
      ])
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.stageFormGroup.invalid) {
      return;
    } else {
      this.createStage$.emit({
        codStage: null,
        codSubtype: null,
        name: this.stageFormGroup.get('name').value,
        description: this.stageFormGroup.get('description').value,
        enabled: true,
        notify: false,
        position: 1
      });
    }
  }

  onCancel() {
    this.goBackOnRouter$.emit(true);
  }
}
