import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export enum ERouterActions {
    RouterGoAction = '[Router] Router Go',
    RouterBackAction = '[Router] Router Back',
    RouterForwardAction = '[Router] Router Forward',
    RouterNavigateAction = '[Router] Router Navigate',
}

export class RouterGo implements Action {
    readonly type = ERouterActions.RouterGoAction;

    constructor(
        public payload: {
            path: any[];
            queryParams?: object;
            extras?: NavigationExtras;
        }
    ) { }
}

export class RouterBack implements Action {
    readonly type = ERouterActions.RouterBackAction;
}

export class RouterForward implements Action {
    readonly type = ERouterActions.RouterForwardAction;
}

export class RouteNavigate implements Action {
    readonly type = ERouterActions.RouterNavigateAction;
    constructor(public payload: { path: string; params?: any; queryParams?: any; data?: any }) { }
}
