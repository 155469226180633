/* Angular */

import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* App */
import { SharedModule } from './../../../../../app/shared/components/shared.module';
import { ListTaskContainerComponent } from './containers/list-task-container/list-task-container.component';
import { ListTaskComponent } from './pages/list-task/list-task.component';
import { ListTaskFormContainerComponent } from './containers/list-task-form-container/list-task-form-container.component';
import { ListTaskFormComponent } from './pages/list-task-form/list-task-form.component';


// Libreria de diseño bancolombia
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { TaskFormComponent } from './components/task-form/task-form.component';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import {BcIconButtonModule} from '@bancolombia/design-system-web/bc-icon-button'
// Librerias de Material
import { MatSelectModule } from '@angular/material/select';


/* This */
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        BcAccordionModule,
        BcTableModule,
        BcButtonModule,
        BcIconModule,
        BcInputModule,
        BcInputSelectModule,
        MatSelectModule,
        BcIconButtonModule
    ],
    declarations: [ListTaskContainerComponent, ListTaskComponent, ListTaskFormContainerComponent, ListTaskFormComponent, TaskFormComponent]
})

export class TaskDashboardModule { }
