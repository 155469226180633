import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../shared/models/other/http/Response.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IAnswerable } from '../../shared/models/answerable/Answerable.interface';

@Injectable({
  providedIn: 'root'
})
export class AnswerableService {

  constructor(private http: HttpClient) { }

  getAnswerablesOfSubtype(subtypeId: string): Observable<IResponse> {
    return this.http.get<IResponse>
      (`${environment.apiEndPoint}/responsibles/subtype/${subtypeId}`);
  }

  saveAnswerable(responsible: IAnswerable): Observable<IResponse> {
    return this.http.post<IResponse>(`${environment.apiEndPoint}/responsibles`, responsible);
  }

}
