import { EOptionActions, OptionActions } from '../../../core/store/actions/option.actions';
import { IOptionState, initialOptionState } from '../../../core/store/state/option.state';

export function optionReducer(
    state = initialOptionState,
    action: OptionActions
): IOptionState {
    switch (action.type) {
        case EOptionActions.GetOptionsSuccessAction: {
            return {
                ...state,
                options: action.payload
            };
        }
        case EOptionActions.GetOptionSuccessAction: {
            return {
                ...state,
                selectedOption: action.payload
            };
        }
        default:
            return state;
    }
}
