import { IGeneralState } from '../../store/state/general.state';
import { IAppState } from '../../store/state/app.state';
import { createSelector } from '@ngrx/store';

const generalState = (state: IAppState) => state.general;

export const selectLoading = createSelector(
    generalState,
    (state: IGeneralState) => state.loading
);
