import { ITemplate } from '../../../shared/models/template/Template.interface';
import { Action } from '@ngrx/store';

export enum ETemplateActions {
    GetTemplateAction = '[Template] Get Template',
    GetTemplateSuccessAction = '[Template] Get Template Success',
    GetTemplatesAction = '[Template] Get Templates',
    GetTemplatesSuccessAction = '[Template] Get Templates Success',
    UpdateTemplateAction = '[Template] Update Template',
    UpdateTemplateSuccessAction = '[Template] Update Template Success',
    UpdateTemplateNotificationsAction = '[Template] Update Template Notifications',
    UpdateTemplateNotificationsSuccessAction = '[Template] Update Template Notifications Success'
}
export class GetTemplates implements Action {
    public readonly type = ETemplateActions.GetTemplatesAction;
    constructor(public payload: string) { }
}

export class GetTemplatesSuccess implements Action {
    public readonly type = ETemplateActions.GetTemplatesSuccessAction;
    constructor(public payload: ITemplate[]) { }
}

export class GetTemplate implements Action {
    public readonly type = ETemplateActions.GetTemplateAction;
    constructor(public payload: any) { }
}

export class GetTemplateSuccess implements Action {
    public readonly type = ETemplateActions.GetTemplateSuccessAction;
    constructor(public payload: ITemplate) { }
}

export class UpdateTemplate implements Action {
    public readonly type = ETemplateActions.UpdateTemplateAction;
    constructor(public payload: any) { }
}

export class UpdateTemplateSuccess implements Action {
    public readonly type = ETemplateActions.UpdateTemplateSuccessAction;
    constructor(public payload: ITemplate) { }
}

export class UpdateTemplateNotifications implements Action {
    public readonly type = ETemplateActions.UpdateTemplateNotificationsAction;
    constructor(public payload: any) { }
}

export class UpdateTemplateNotificationsSuccess implements Action {
    public readonly type = ETemplateActions.UpdateTemplateNotificationsSuccessAction;
    constructor(public payload: ITemplate) { }
}

export type TemplateActions = GetTemplates | GetTemplatesSuccess | GetTemplate | GetTemplateSuccess |
    UpdateTemplate | UpdateTemplateSuccess | UpdateTemplateNotifications | UpdateTemplateNotificationsSuccess;
