import { ITemplateState } from '../../store/state/template.state';
import { IAppState } from '../../store/state/app.state';
import { createSelector } from '@ngrx/store';

const templateState = (state: IAppState) => state.template;

export const selectTemplateList = createSelector(
    templateState,
    (state: ITemplateState) => state.templates
);

export const selectSelectedTemplate = createSelector(
    templateState,
    (state: ITemplateState) => state.selectedTemplate
);
