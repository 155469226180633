import { RouterGo } from '../../../../../../core/store/actions/router.actions';
import { ITemplate } from '../../../../../../shared/models/template/Template.interface';
import { GetTemplates } from '../../../../../../core/store/actions/template.actions';
import { selectTemplateList } from '../../../../../../core/store/selectors/template.selectors';
import { selectLoading } from '../../../../../../core/store/selectors/general.selectors';
import { GetLoading } from '../../../../../../core/store/actions/general.actions';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from '../../../../../../core/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { selectStageList } from '../../../../../../../app/core/store/selectors/stage.selectors';
import { GetStages } from '../../../../../../../app/core/store/actions/stage.actions';


@Component({
  selector: 'app-list-template-container',
  templateUrl: './list-template-container.component.html',
  host: {
    class: 'container-customized'
  }
})
export class ListTemplateContainerComponent implements OnInit {

  stages$ = this.store.pipe(select(selectStageList));
  templates$ = this.store.pipe(select(selectTemplateList));
  loading$ = this.store.pipe(select(selectLoading));

  constructor(private store: Store<IAppState>, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.store.dispatch(new GetTemplates(this.activatedRoute.parent.snapshot.params['id']));
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.templateList }));
    this.store.dispatch(new GetStages(this.activatedRoute.parent.snapshot.params['id']));
  }

  navigateToTemplate(option: ITemplate) {
    this.store.dispatch(new RouterGo({
      path: [`plantilla/${option.codStage}`], extras: {
        relativeTo: this.activatedRoute.parent
      }
    }));
  }
}
