<section class="box">
    <header class="box-header">
        <h5>Estados</h5>
    </header>
    <main class="box-body">
        <ul class="states-list font-normal">
            <form [formGroup]="editStateForm" *ngIf="areThereEnoughStates()">
                <li *ngFor="let item of editStateForm.get('statesArray')['controls']; let individualStateForm = index;"
                    id="form-state-{{item.value.codState}}" formArrayName="statesArray"><span
                        class="space-between"></span>
                    <div [formGroupName]="individualStateForm" class="input-content">
                        <mat-form-field appearance="outline">
                            <input matInput value="{{item.value.name}}" formControlName="name" />
                        </mat-form-field>
                        <div class="action-buttons" *ngIf="!isAvailable(item.value.codState)">
                            <button id="action-edit-state-{{item.value.name}}" (click)="toggleEditingMode(item.value)">
                                <em class="fas fa-pencil-alt animated-scale"></em>
                            </button>
                            <button class="action-button" id="action-delete-state-{{item.value.name}}"
                                (click)="onDeleteState(item.value)">
                                <em class="fas fa-trash-alt animated-scale"></em>
                            </button>
                        </div>
                        <div class="action-buttons" *ngIf="isAvailable(item.value.codState)">
                            <button class="action-button" id="action-confirm-edit-state-{{item.value.name}}"
                                (click)="onEditState(item)">
                                <em class="fas fa-save animated-scale"></em>
                            </button>
                            <button class="action-button" id="action-cancel-edit-state-{{item.value.name}}"
                                (click)="onCancelEditMode(item)">
                                <em class="fa fa-times-circle animated-scale"></em>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="item.controls.name.errors">
                        <app-form-error-box *ngIf="item.controls.name.errors.pattern"
                            message="Verifique que el nuevo estado sea alfanumérico"></app-form-error-box>
                        <app-form-error-box *ngIf="item.controls.name.errors.required"
                            message="Por favor escriba un nombre para el nuevo estado"></app-form-error-box>
                        <app-form-error-box *ngIf="item.controls.name.errors.maxlength"
                            message="Por favor no exceda el límite de caracteres. Límite:
                            {{item.controls.name.errors.maxlength.actualLength}} /
                            {{item.controls.name.errors.maxlength.requiredLength}}">
                        </app-form-error-box>
                    </div>
                </li>
            </form>
        </ul>
    </main>
    <footer class="box-footer">
        <a type="button" (click)="onAddNewState()" class="btn-new-state" *ngIf="!isCreatingState"><em
                class="fas fa-plus-circle"></em> Crear nuevo estado
        </a>
        <form [formGroup]="newStateForm" (ngSubmit)="onCreateState()" *ngIf="isCreatingState">
            <div class="input-content">
                <mat-form-field appearance="outline">
                    <mat-label>Nuevo estado</mat-label>
                    <input matInput id="form-new-state" placeholder="Estado nuevo" formControlName="stateName" />
                </mat-form-field>
                <div class="action-buttons">
                    <button id="action-save-state" type="submit">
                        <em class="fas fa-save animated-scale"></em>
                    </button>
                    <button id="action-cancel-save-state" (click)="initResetCreatingMode()">
                        <em class="fa fa-times-circle animated-scale"></em>
                    </button>
                </div>
            </div>
            <app-form-error-box *ngIf="alreadySubmitted && newStateForm.controls.stateName.errors?.pattern"
                message="Verifique que el nuevo estado sea alfanumérico"></app-form-error-box>
            <app-form-error-box *ngIf="alreadySubmitted && newStateForm.controls.stateName.errors?.required"
                message="Por favor escriba un nombre para el nuevo estado"></app-form-error-box>
            <app-form-error-box *ngIf="alreadySubmitted && newStateForm.controls.stateName.errors?.maxlength"
                message="Por favor no exceda el límite de caracteres. Límite:
                {{newStateForm.controls.stateName.errors.maxlength.actualLength}} /
                {{newStateForm.controls.stateName.errors.maxlength.requiredLength}}">
            </app-form-error-box>
        </form>
    </footer>
</section>