import { IAppState } from '../../../core/store/state/app.state';
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { subtypeReducer } from './subtype.reducers';
import { generalReducer } from './general.reducers';
import { optionReducer } from './option.reducers';
import { templateReducer } from './template.reducers';
import { stageReducer } from './stage.reducers';
import { fieldReducer } from './field.reducers';
import { taskReducer } from './task.reducers';
import { answerableReducer } from './answerable.reducers';
import { contactReducer } from './contact.reducers';



export const appReducers: ActionReducerMap<IAppState, any> = {
    router: routerReducer,
    subtype: subtypeReducer,
    general: generalReducer,
    option: optionReducer,
    template: templateReducer,
    stage: stageReducer,
    field: fieldReducer,
    task: taskReducer,
    answerable: answerableReducer,
    contact: contactReducer,
};
