import { ITemplate } from '../../../shared/models/template/Template.interface';

export interface ITemplateState {
    templates: ITemplate[];
    selectedTemplate: ITemplate;
}

export const initialTemplateState: ITemplateState = {
    templates: [],
    selectedTemplate: null
};
