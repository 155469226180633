import { OPTIONS_CONST } from '../../../app/shared/constants/options.constants';

const indexData0 = 0;
const indexData1 = 1;
const indexData2 = 2;
const indexData3 = 3;

export const STAGE_TEXT = {
    label: {
        title: 'Etapas',
        list: 'Selecciona una etapa para editarla, arrástralas para cambiar su orden.',
        selected: 'Edita el contenido de tu etapa.',
        unselected: 'Selecciona el tipo de solicitud para ver las etapas correspondientes.'
    },
    routes: {
        list: 'etapas'
    },
    option: OPTIONS_CONST.data[indexData1]
};

export const TEMPLATE_TEXT = {
    label: {
        title: 'Plantillas',
        list: 'Selecciona una etapa para crear o actualizar las plantillas.',
        selected: 'Edita el contenido de tu plantilla.',
        unselected: 'Selecciona el tipo de solicitud para ver las plantillas correspondientes.'
    },
    routes: {
        list: 'plantillas'
    },
    option: OPTIONS_CONST.data[indexData2]
};



export const TASK_TEXT = {
    label: {
        title: 'Tareas',
        list: 'Seleccione una etapa para ver, modificar o crear tareas asociadas a ella.',
        selected: 'Edita el contenido de una tarea.',
        unselected: 'Selecciona el tipo de solicitud para ver las tareas correspondientes.'
    },
    routes: {
        list: 'tareas'
    },
    option: OPTIONS_CONST.data[indexData3]
};

export const TASK_FORM_TEXT = {
    label: {
        title: 'Tareas',
        list: 'Cargando ...',
        selected: 'Edita el contenido de una tarea.',
        unselected: 'Selecciona el tipo de solicitud para ver las tareas correspondientes.'
    },
    routes: {
        list: 'tareas'
    },
    option: OPTIONS_CONST.data[indexData3]
};

export const INDEX_TEXT = {
    label: {
        title: '¡Bienvenido!',
        list: '',
        selected: '',
        unselected: 'Selecciona el tipo de solicitud para ver el tablero correspondiente.'
    },
    routes: {
        list: ''
    },
    option: OPTIONS_CONST.data[indexData0]
};
