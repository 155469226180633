import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthManagerService {

  private http: HttpClient;
  
  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);    
  }

  getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'Internet Explorer';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Navegador desconocido';
    }
  }

  retrieveSession(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Browser-Tracking', this.getBrowserName());
    const token = this.getToken();
    headers = headers.set('Authorization', 'Bearer ' + token.secret);
    return this.http.get<any>(`${environment.securityEndPoint}/session`, { headers });
  }

  createSession(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Browser-Tracking', this.getBrowserName());
    const token = this.getToken();
    headers = headers.set('Authorization', 'Bearer ' + token.secret);
    return this.http.post<any>(`${environment.securityEndPoint}/session`, {}, { headers });
  }

  getToken(){
    const msalkey = JSON.parse(sessionStorage.getItem('msal.token.keys.' + environment.azureActiveDirectory.clientId));
    return JSON.parse(sessionStorage.getItem(msalkey.accessToken[1]))
  }

}
