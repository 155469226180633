import { IAppState } from '../../../core/store/state/app.state';
import { createSelector } from '@ngrx/store';
import { ITaskState } from '../state/task.state';

const taskState = (state: IAppState) => state.task;

export const selectTaskList = createSelector(
    taskState,
    (state: ITaskState) => state.tasks
);


