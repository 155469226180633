export enum ESessionContent {
    ok = 'Session OK',
    new_login = 'Another IP Address has logged in',
    must_create_session = 'Must create a new session',
    expired = 'Session expired',
    close_to_expire = 'Session is close to pass away',
    different_browser = 'User has another session in a different browser',
    expired_jwt = 'Expired JWT',
    unknown_error = 'Unknown Error'
}
