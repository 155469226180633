import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IResponse } from '../../../shared/models/other/http/Response.interface';
import { GetLoading } from '../../../core/store/actions/general.actions';
import { RESET_LOADING_BODY } from '../../../shared/constants/store.constants';
import { ContactService } from '../../../core/services/contact.service';
import { IAppState } from '../../../core/store/state/app.state';

import {
  EContactActions,
  GetContactsSuccess,
  GetContacts,
  GetTaskContacts,
  GetTaskContactsSuccess,
} from '../../../core/store/actions/contact.actions';

import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ContactEffects {

  getContacts$ = createEffect(() =>this.actions$.pipe(
    ofType<GetContacts>(EContactActions.GetContactsAction),
    switchMap(action => this.contactService.getContactsOfSubtype(action.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoading(RESET_LOADING_BODY));
      return of(new GetContactsSuccess(response.data));
    })
  ));

  getTaskContacts$ = createEffect(() =>this.actions$.pipe(
    ofType<GetTaskContacts>(EContactActions.GetTaskContactsAction),
    switchMap(action => this.contactService.getTaskContactsOfStage(action.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoading(RESET_LOADING_BODY));
      return of(new GetTaskContactsSuccess(response.data));
    })
  ));


  constructor(
    private contactService: ContactService,
    private actions$: Actions,
    private store: Store<IAppState>
  ) { }
}
