import { IFieldStage } from '../../../shared/models/field/FieldStage.interface';
import { IField } from '../../../shared/models/field/Field.interface';
import { Action } from '@ngrx/store';

export enum EFieldActions {
    GetFieldSubtypeAction = '[Field] Get Subtype Fields',
    GetFieldSubtypeSuccessAction = '[Field] Get Subtype Fields Success',
    GetFieldTemplateAction = '[Field] Get Template Fields',
    GetFieldTemplateSuccessAction = '[Field] Get Template Fields Success',
    UpdateFieldTemplateAction = '[Field] Update Template Fields',
    UpdateFieldTemplateSuccessAction = '[Field Update Template Fields Success'
}

export class GetFieldsSubtype implements Action {
    public readonly type = EFieldActions.GetFieldSubtypeAction;
    constructor(public payload: IField) { }
}

export class GetFieldsSubtypeSuccess implements Action {
    public readonly type = EFieldActions.GetFieldSubtypeSuccessAction;
    constructor(public payload: IField[]) { }
}

export class GetFieldsTemplate implements Action {
    public readonly type = EFieldActions.GetFieldTemplateAction;
    constructor(public payload: IFieldStage) { }
}

export class GetFieldsTemplateSuccess implements Action {
    public readonly type = EFieldActions.GetFieldTemplateSuccessAction;
    constructor(public payload: IFieldStage[]) { }
}

export class UpdateFieldsTemplate implements Action {
    public readonly type = EFieldActions.UpdateFieldTemplateAction;
    constructor(public payload: IFieldStage[], public stageId: string) { }
}

export class UpdateFieldsTemplateSuccess implements Action {
    public readonly type = EFieldActions.UpdateFieldTemplateSuccessAction;
    constructor(public payload: IFieldStage[]) { }
}


export type FieldActions = GetFieldsSubtype | GetFieldsSubtypeSuccess | GetFieldsTemplate |
    GetFieldsTemplateSuccess | UpdateFieldsTemplate | UpdateFieldsTemplateSuccess;
