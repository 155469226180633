<div class="box box-info" *ngIf="visible">
    <main class="box-body">
        <section id="template-info-component">
            <article id="template-info-body">
                <em class="fas fa-info-circle fa-2x"></em>
                <main id="template-info-message">
                    <p>Recuerda utilizar los siguientes campos en los mensajes como variables</p>
                    <p><span *ngFor="let field of fields"><span class="variable">[{{field.name}}]</span>
                            {{field.description}} </span>
                    </p>
                </main>
            </article>
            <a class="close" type="button" (click)="onSwitchVisible()">
                <em class="fas fa-times fa-lg"></em>
            </a>
        </section>
    </main>
</div>