import { IAnswerableState } from '../../store/state/answerable.state';
import {initialAnswerableState} from '../../store/state/answerable.state';
import { AnswerableActions, EAnswerableActions } from '../../store/actions/answerable.actions';

export function answerableReducer(
    state = initialAnswerableState,
    action: AnswerableActions
): IAnswerableState {
    if (action.type === EAnswerableActions.SaveAnswerableSuccessAction ||
            action.type === EAnswerableActions.GetAnswerablesSuccessAction) {
        return {
            ...state,
            answerables: action.payload
        };
    } else {
        return state;
    }
}
