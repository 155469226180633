<section class="template">
    <app-loading *ngIf="componentIsLoading()"></app-loading>
    <div *ngIf="!componentIsLoading()">
        <h3><img src="{{property.option.iconActive}}" alt="Icon"> {{property.label.title}}</h3>
        <p>{{property.label.selected}}</p>
        <div class="container">
            <app-template-info-box [fields]="fields" class="info-template-fields">
            </app-template-info-box>
            <main class="template-body">
                <p #pageTitle></p>
                <section class="box email">
                    <header class="box-header">
                        <h5><em class="fas fa-envelope"></em> Correo electrónico</h5>
                        <div class="box-options">
                            <a (click)="onEdit('email')"><em class="fas fa-pencil-alt animated-scale"></em></a>
                        </div>
                    </header>
                    <main class="box-body">
                        <form *ngIf="emailFormGroup" [formGroup]="emailFormGroup">
                            <mat-form-field appearance="fill">
                                <mat-label>Código del mensaje de correo electrónico</mat-label>
                                <input matInput formControlName="emailId" [value]="emailFormGroup.value.emailId" />
                                <mat-hint align="end">ID de plantilla creado en el aplicativo de envío
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="emailFormGroup.controls.emailId.errors && submitedEmail">
                                <app-form-error-box *ngIf="emailFormGroup.controls.emailId.errors.pattern"
                                    message="Por favor escriba solo números en el ID del email">
                                </app-form-error-box>
                                <app-form-error-box *ngIf="emailFormGroup.controls.emailId.errors.required"
                                    message="Por favor escriba un ID para la plantilla de email">
                                </app-form-error-box>
                                <app-form-error-box *ngIf="emailFormGroup.controls.emailId.errors.max"
                                    message="Por favor verifique que no exceda más de {{emailFormGroup.controls.emailId.errors.max.max.toString().length}} caracteres">
                                </app-form-error-box>
                                <app-form-error-box *ngIf="emailFormGroup.controls.emailId.errors.min"
                                    message="Por favor verifique que el ID sea un número mayor a cero">
                                </app-form-error-box>
                            </div>

                            <mat-form-field appearance="fill">
                                <mat-label>Remitente correo electrónico</mat-label>
                                <input matInput type="text" placeholder="bancolombia" formControlName="emailSender"
                                    [value]="emailFormGroup.value.emailSender" />
                                <span matSuffix>@solicitudesgrupobancolombia.com.co</span>
                                <mat-hint align="end">Ingrese solo el nombre del correo electrónico
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="emailFormGroup.controls.emailSender.errors && submitedEmail">
                                <app-form-error-box *ngIf="emailFormGroup.controls.emailSender.errors.pattern"
                                    message="Por favor escriba solo letras minúsculas o mayúsculas">
                                </app-form-error-box>
                                <app-form-error-box *ngIf="emailFormGroup.controls.emailSender.errors.required"
                                    message="Por favor escriba un remitente">
                                </app-form-error-box>
                                <app-form-error-box *ngIf="emailFormGroup.controls.emailSender.errors.maxlength"
                                    message="Por favor no exceda el límite de caracteres. Límite:
                                {{emailFormGroup.controls.emailSender.errors.maxlength.actualLength}} /
                                {{emailFormGroup.controls.emailSender.errors.maxlength.requiredLength}}">
                                </app-form-error-box>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label>Campos</mat-label>
                                <mat-select formControlName="emailFields" multiple appearance="fill">
                                    <mat-option *ngFor="let field of fields" [value]="field.codFieldSubtype">
                                        {{field.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint align="end">Campos de la plantilla de correo electrónico
                                </mat-hint>
                            </mat-form-field>
                            <div *ngIf="emailFormGroup.controls.emailFields.errors && submitedEmail">
                                <app-form-error-box *ngIf="emailFormGroup.controls.emailFields.errors.required"
                                    message="Por favor escoja los campos pertenecientes a la plantilla de email">
                                </app-form-error-box>
                            </div>
                        </form>
                    </main>
                    <footer class="box-footer">
                        Notificaciones
                        <app-switch-template-form-container type="email" [template]="template">
                        </app-switch-template-form-container>
                    </footer>
                </section>
                <section class="box sms">
                    <header class="box-header">
                        <h5><em class="fas fa-mobile-android-alt"></em> SMS</h5>
                        <div class="box-options">
                            <a (click)="onEdit('sms')"><em class="fas fa-pencil-alt animated-scale"></em></a>
                        </div>
                    </header>
                    <main class="box-body">
                        <form *ngIf="smsFormGroup" [formGroup]="smsFormGroup">
                            <mat-form-field appearance="fill">
                                <mat-label>Mensaje de texto</mat-label>
                                <textarea matInput formControlName="smsText" [value]="getTemplateProperty('smsMessage')"
                                    (ngModelChange)="smsTextOnChange()" rows="4"></textarea>
                                <mat-hint align="end">Recuerde ingresar los campos variables. Ej: [Variable]
                                </mat-hint>
                            </mat-form-field>
                            <div *ngIf="smsFormGroup.controls.smsText.errors && submitedSms">
                                <app-form-error-box *ngIf="smsFormGroup.controls.smsText.errors.pattern"
                                    message="Verifique que el mensaje de texto sea alfanumérico y solo contenga variables">
                                </app-form-error-box>
                                <app-form-error-box *ngIf="smsFormGroup.controls.smsText.errors.required"
                                    message="Por favor escriba un mensaje de texto para la plantilla">
                                </app-form-error-box>
                                <app-form-error-box *ngIf="smsFormGroup.controls.smsText.errors.maxlength"
                                    message="Por favor no exceda el límite de caracteres. Límite:
                                    {{smsFormGroup.controls.smsText.errors.maxlength.actualLength}} /
                                    {{smsFormGroup.controls.smsText.errors.maxlength.requiredLength}}">
                                </app-form-error-box>
                            </div>
                            <div class="previous-message">
                                <h5>Vista previa del mensaje</h5>
                                <div [innerHTML]="smsTextChild | safe: 'html'"></div>
                            </div>
                        </form>
                    </main>
                    <footer class="box-footer">
                        Notificaciones
                        <app-switch-template-form-container type="sms" [template]="template">
                        </app-switch-template-form-container>
                    </footer>
                </section>
            </main>
            <div class="w-100 container-center" *ngIf="!isReadOnly('email') || !isReadOnly('sms')">
                <button (click)="onCancel()" class="btn btn-secondary">Cancelar</button>
                <button (click)="onSubmit()" class="btn btn-primary">Guardar cambios</button>
            </div>
            <p #optionsButton></p>
        </div>
    </div>
</section>