import { IFieldStage } from '../../shared/models/field/FieldStage.interface';
import { environment } from '../../../environments/environment';
import { IResponse } from '../../shared/models/other/http/Response.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FieldStageService {

  constructor(private http: HttpClient) { }

  getFieldsFromTemplate(stageId: string): Observable<IResponse> {
    return this.http.get<IResponse>(`${environment.apiEndPoint}/fields/template/${stageId}`);
  }

  saveTemplateFields(stageId: string, fields: IFieldStage[]): Observable<IResponse> {
    return this.http.post<IResponse>(`${environment.apiEndPoint}/fields/template/${stageId}`, fields);
  }
}
