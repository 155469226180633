import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ESessionContent } from 'src/app/shared/models/session/Session.enum';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {

      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          const unauthorizedCode = 401;
          const intervalServerErrorCode = 500;
          const enknownError = 0;
          if (err.status === unauthorizedCode) {
            if (this.router.url !== '/login') {
              this.router.navigate(['/unauthorized']);
            }
          } else if (
            err.status >= intervalServerErrorCode ||
            (err.status === enknownError && err.statusText === ESessionContent.unknown_error)
          ) {
            this.router.navigate(['/service-unavailable']);
          }
        }
      })
    );
  }
}
