import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UpdateTemplate,
  UpdateTemplateSuccess,
  UpdateTemplateNotifications,
  UpdateTemplateNotificationsSuccess
} from '../../../../app/core/store/actions/template.actions';
import { TemplateService } from '../../../core/services/template.service';
import { GetTemplates, ETemplateActions, GetTemplatesSuccess, GetTemplateSuccess } from '../../../../app/core/store/actions/template.actions';
import { createEffect , ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IResponse } from '../../../../app/shared/models/other/http/Response.interface';
import { GetLoadingSuccess } from '../../../../app/core/store/actions/general.actions';
import { RESET_LOADING_BODY } from '../../../../app/shared/constants/store.constants';
import { IAppState } from '../../../../app/core/store/state/app.state';


import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofRoute, mapToParam } from '../../../../app/shared/pipes/router-store.pipe';

@Injectable()
export class TemplateEffects {

  templateRouted$ = createEffect(()=>
    this.actions$.pipe(
      ofRoute('plantilla/:id'),
      mapToParam<string>('id'),
      switchMap(id => this.templateService.getTemplateByCodStage(id)),
      switchMap((response: IResponse) => {
        this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
        return of(new GetTemplateSuccess(response.data[0]));
      }
      )
    )
  );

  updateTemplate$ = createEffect(() =>this.actions$.pipe(
    ofType<UpdateTemplate>(ETemplateActions.UpdateTemplateAction),
    switchMap(payload => this.templateService.updateTemplate(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se actualizó la plantilla ${response.data[0].stageName}`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      return of(new UpdateTemplateSuccess(response.data[0]));
    })
  ));

  updateTemplateNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTemplateNotifications>(ETemplateActions.UpdateTemplateNotificationsAction),
    switchMap(payload => this.templateService.updateTemplateNotifications(payload.payload)),
    switchMap((response: IResponse) => {
      this.notification.open(`¡Éxito! Se actualizaron las notificaciones para la plantilla ${response.data[0].stageName}`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      return of(new UpdateTemplateNotificationsSuccess(response.data[0]));
    })
  ));

  getTemplates$ = createEffect(() => this.actions$.pipe(
    ofType<GetTemplates>(ETemplateActions.GetTemplatesAction),
    switchMap(payload => this.templateService.getAllTemplates(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      return of(new GetTemplatesSuccess(response.data));
    })
  ));

  constructor(
    private templateService: TemplateService,
    private actions$: Actions,
    private store: Store<IAppState>,
    private notification: MatSnackBar
  ) { }
}
