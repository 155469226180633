import { ISubtype } from '../../../../shared/models/subtype/Subtype.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Input()
  subtype: ISubtype;

  constructor() { }

  ngOnInit() {
  }

  isSubtypeSelected(): boolean {
    return this.subtype ? true : false;
  }

}
