import { IAnswerable } from '../../../shared/models/answerable/Answerable.interface';
import { Action } from '@ngrx/store';

export enum EAnswerableActions {
    GetAnswerablesAction = '[Answerable] Get Answerables',
    GetAnswerablesSuccessAction = '[Answerable] Get Answerables Success',
    SaveAnswerableAction = '[Answerable] Save Answerable',
    SaveAnswerableSuccessAction = '[Answerable] Save Answerables Success',
}

export class GetAnswerables implements Action {
    public readonly type = EAnswerableActions.GetAnswerablesAction;
    constructor(public payload: string) { }
}

export class GetAnswerablesSuccess implements Action {
    public readonly type = EAnswerableActions.GetAnswerablesSuccessAction;
    constructor(public payload: IAnswerable[]) { }
}

export class SaveAnswerable implements Action {
    public readonly type = EAnswerableActions.SaveAnswerableAction;
    constructor(public payload: IAnswerable) { }
}

export class SaveAnswerableSuccess implements Action {
    public readonly type = EAnswerableActions.SaveAnswerableSuccessAction;
    constructor(public payload: IAnswerable[]) {
    }
}

export type AnswerableActions = GetAnswerables | GetAnswerablesSuccess | SaveAnswerable | SaveAnswerableSuccess;
