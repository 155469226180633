import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../shared/models/other/http/Response.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  getContactsOfSubtype(subtypeId: string): Observable<IResponse> {
    return this.http.get<IResponse>
      (`${environment.apiEndPoint}/contacts/subtype/${subtypeId}`);
  }

  getTaskContactsOfStage(stageId: string): Observable<IResponse> {
    return this.http.get<IResponse>
      (`${environment.apiEndPoint}/taskscontacts/stage/${stageId}`);
  }

}
