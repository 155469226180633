import { IState } from '../models/state/State.interface';

export const capitalizeString = (text: string): string => {
    return text ?
        `${text.charAt(0).toUpperCase()}${(text.length > 1) ?
            text.substr(1, text.length).toLowerCase() :
            text.charAt(1).toLowerCase()}` : '';
};

export const reduceArrayToN = (array: any[], slices: number): any[] => {
    const maxlengthArray = 3;
    return (array.length > maxlengthArray) ? array.slice(0, slices) : array;
};

export const normalizeStatesToText = (array: IState[]): string[] => {
    let text = [];
    text = array.map((value, _) => {
        return `${value.name}`;
    });
    return text;
};
