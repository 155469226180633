import { ISubtype } from '../../../../shared/models/subtype/Subtype.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-product',
  templateUrl: './home-product.component.html',
  styleUrls: ['./home-product.component.scss']
})
export class HomeProductComponent implements OnInit {

  @Input()
  subtype: ISubtype;

  constructor() { }

  ngOnInit() {
  }

  getSubtypeName(): string {
    return (this.subtype) ? this.subtype.name : '';
  }
}
