import { ISubtypeState } from '../../../core/store/state/subtype.state';
import { IAppState } from '../../../core/store/state/app.state';
import { createSelector } from '@ngrx/store';

const subtypeState = (state: IAppState) => state.subtype;

export const selectSubtypeList = createSelector(
    subtypeState,
    (state: ISubtypeState) => state.subtypes
);

export const selectSelectedSubtype = createSelector(
    subtypeState,
    (state: ISubtypeState) => state.selectedSubtype
);
