import { RouterGo, RouteNavigate } from '../../../core/store/actions/router.actions';
import { ERouterActions } from '../../../core/store/actions/router.actions';
import { IAppState } from '../../../core/store/state/app.state';
import { Store } from '@ngrx/store';
import { Router, ActivationEnd } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, tap, filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class RouterEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private location: Location,
        private store: Store<IAppState>
    ) {
        this.listenToRouter();
    }

    navigate$ = createEffect( () => this.actions$.pipe(
        ofType(ERouterActions.RouterGoAction),
        map((action: RouterGo) => action.payload),
        tap(({ path, queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras }))
    ), { dispatch: false } );

    navigateBack$ = createEffect( () => this.actions$.pipe(
        ofType(ERouterActions.RouterBackAction), tap(() => this.location.back())
    ), { dispatch: false } );

    navigateForward$ = createEffect( () => this.actions$.pipe(
        ofType(ERouterActions.RouterForwardAction),
        tap(() => this.location.forward())
    ), { dispatch: false } );

    private listenToRouter() {
        this.router.events.pipe(
            filter(event => event instanceof ActivationEnd)
        ).subscribe((event: ActivationEnd) =>
            this.store.dispatch(new RouteNavigate({
                params: { ...event.snapshot.params },
                path: event.snapshot.routeConfig.path
            }))
        );
    }
}
