import { FieldActions, EFieldActions } from '../../../core/store/actions/field.actions';
import { initialFieldState, IFieldState } from '../../../core/store/state/field.state';

export function fieldReducer(
    state = initialFieldState,
    action: FieldActions
): IFieldState {
    switch (action.type) {
        case EFieldActions.GetFieldSubtypeSuccessAction: {
            return {
                ...state,
                selectedSubtypeFields: action.payload
            };
        }
        case EFieldActions.UpdateFieldTemplateSuccessAction:
        case EFieldActions.GetFieldTemplateSuccessAction: {
            return {
                ...state,
                selectedTemplateFields: action.payload
            };
        }
        default:
            return state;
    }
}
