<section class="template">
    <h3><img src="{{property.option.iconActive}}" alt="Icon"> {{property.label.title}}</h3>
    <p>{{property.label.list}}</p>
    <section class="info-state">
        <article>
            <em class="fas fa-circle enabled"></em> Etapa activa
        </article>
        <article>
            <em class="fas fa-circle disabled"></em> Etapa inactiva
        </article>
    </section>
    <app-loading *ngIf="componentIsLoading()"></app-loading>
    <main *ngIf="!componentIsLoading()">
        <app-no-content *ngIf="!areThereTemplates()"></app-no-content>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4"
            aria-describedby="lista de plantillas" *ngIf="areThereTemplates()">

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef id="template-list-state">
                    <h4>Estado</h4>
                </th>
                <td mat-cell *matCellDef="let template">
                    <em [className]="template.stageEnabled ? 'fas fa-circle enabled' : 'fas fa-circle disabled'"></em>
                </td>
            </ng-container>

            <ng-container matColumnDef="stage">
                <th mat-header-cell *matHeaderCellDef id="template-list-stage">
                    <h4>Nombre de la etapa</h4>
                </th>
                <td mat-cell *matCellDef="let template" (click)="navigateToTemplate(template)"
                    class="template-stage-name">
                    <u>{{template.stageName}}</u>
                </td>
            </ng-container>

            <ng-container matColumnDef="sms">
                <th mat-header-cell *matHeaderCellDef id="template-list-sms">
                    <h4>SMS</h4>
                </th>
                <td mat-cell *matCellDef="let template">
                    <app-switch-template-form-container type="sms" [template]="template">
                    </app-switch-template-form-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef id="template-list-email">
                    <h4>Correo electrónico</h4>
                </th>
                <td mat-cell *matCellDef="let template">
                    <app-switch-template-form-container type="email" [template]="template">
                    </app-switch-template-form-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </main>
</section>