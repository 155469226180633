import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {

  @Input()
  content: string;

  @Input()
  type: string;

  visible: boolean;

  constructor() { }

  ngOnInit() {
    this.visible = true;
  }

  onSwitchVisible(): void {
    this.visible = !this.visible;
  }

  getIcon(): string {
    const style = 'fas';
    const size = 'fa-2x';
    if (this.type === 'warning') {
      return `${style} fa-exclamation-circle ${size} warning`;
    } else {
      return `${style} fa-info-circle ${size} info`;
    }
  }
}
