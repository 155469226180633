<aside class="tracking-aside">
    <section id="sidebar-navigation">
        <br>
        <h2>Seguimiento a las solicitudes</h2>
        <app-product-list-container></app-product-list-container>
        <app-loading *ngIf="componentIsLoading()" class="text-center"></app-loading>
        <mat-list *ngIf="!componentIsLoading()">
            <mat-list-item *ngFor="let option of options" (click)="navigateToOption(option)" [ngClass]="{
                'item-active': isIconActive(option),
                'option-list-item': true}">
                <div class="option-item bc-justify-content-space-between" *ngIf="option.featured">
                    <div  class="option-item"> 
                            <img src={{getOptionIcon(option)}} alt={{option.name}} />  
                            {{option.name}}                 
                    </div>
                    <em class="fas fa-chevron-right fa-xs"  *ngIf="option.featured"></em>
                </div>                
            </mat-list-item>
        </mat-list>
    </section>
    <button class="btn btn-secondary" type="button" (click)="onLogout()">
        <em class="fas fa-sign-out-alt"></em>
        CERRAR SESIÓN</button>
</aside>