import { IFolderSubtype } from './../../../shared/models/folder/FolderSubtype.interface';
import { ISubtype } from '../../../shared/models/subtype/Subtype.interface';

export interface ISubtypeState {
    subtypes: IFolderSubtype[];
    selectedSubtype: ISubtype;
}

export const initialSubtypeState: ISubtypeState = {
    subtypes: [],
    selectedSubtype: null
};
