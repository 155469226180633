import { UpdateFieldsTemplate } from '../../../core/store/actions/field.actions';
import { RESET_LOADING_BODY } from '../../../shared/constants/store.constants';
import { FieldStageService } from '../../../core/services/field-stage.service';
import {
  GetFieldsSubtypeSuccess,
  GetFieldsTemplateSuccess,
  EFieldActions,
  UpdateFieldsTemplateSuccess
} from '../../../core/store/actions/field.actions';
import { IResponse } from '../../../shared/models/other/http/Response.interface';
import { FieldService } from '../../../core/services/field.service';
import { ofRoute, mapToParam } from '../../../shared/pipes/router-store.pipe';
import { GetLoadingSuccess } from '../../../core/store/actions/general.actions';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';

import { IAppState } from '../../../core/store/state/app.state';

@Injectable()
export class FieldEffects {

  
  updateEmailFields$ = createEffect(()=> this.actions$.pipe(
    ofType<UpdateFieldsTemplate>(EFieldActions.UpdateFieldTemplateAction),
    switchMap((payload) => {
      return this.fieldStageService.saveTemplateFields(payload.stageId, payload.payload);
    }),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      return of(new UpdateFieldsTemplateSuccess(response.data));
    })
  ));

  fieldsTemplateRouted$ =  createEffect(()=> this.actions$.pipe(
    ofRoute('plantilla/:id'),
    mapToParam<string>('id'),
    switchMap(id => this.fieldStageService.getFieldsFromTemplate(id)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      return of(new GetFieldsTemplateSuccess(response.data));
    }
    )
  ));

  fieldsSubtypeRouted$ =  createEffect(()=> this.actions$.pipe(
    ofRoute('producto/:id'),
    mapToParam<string>('id'),
    switchMap(id => this.fieldService.getSubtypeFields(id)),
    switchMap((response: IResponse) => {
      return of(new GetFieldsSubtypeSuccess(response.data));
    }
    )
  ));

  constructor(
    private fieldService: FieldService,
    private fieldStageService: FieldStageService,
    private actions$: Actions,
    private store: Store<IAppState>
  ) { }
}
