<section>
<div class="bc-flex bc-direction-row bc-align-items-center">
  <img src="{{property.option.iconActive}}" alt="Icon">
  <h3>{{property.label.title}}</h3>
</div>
<p>{{property.label.list}}</p>
<br>
<main *ngIf="!componentIsLoading()">
  <app-no-content *ngIf="!areThereStages()"></app-no-content>
  <app-loading *ngIf="componentIsLoading()"></app-loading>
  <bc-table-container class="bc-col-12 bc-w-100" *ngIf="areThereStages()">
    <bc-table-content>
      <table caption="tabla" bc-table>
        <thead>
          <tr type="header">
            <th id="" bc-th *ngFor="let item of headersTable" class="colum-center noHover"><label>{{item.name}}</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr bc-tr *ngFor="let stage of listStages">
            <td bc-td type="left" class="colum-center"><label>{{stage.position}}</label></td>
            <td bc-td type="left" class="colum-decoration"><label (click)="navigateToTask(stage)">
                {{stage.name}}</label></td>
            <td bc-td type="left" class="colum-center"><label>{{getTasksOf(stage.codStage)}}</label></td>
          </tr>
        </tbody>
      </table>
    </bc-table-content>
    <bc-table-footer>
    </bc-table-footer>
  </bc-table-container>
</main>
</section>