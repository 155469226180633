import { IProperty } from '../../../../../../shared/models/other/property/Property.interface';
import { STAGE_TEXT } from './../../../../../../shared/constants/text.constants';
import { IState } from '../../../../../../shared/models/state/State.interface';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { IStage } from '../../../../../../../app/shared/models/stage/Stage.interface';
import { ILoading } from '../../../../../../shared/models/other/store/Loading.interface';
import { isComponentLoading } from '../../../../../../shared/constants/store.constants';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { noop } from 'rxjs';

@Component({
  selector: 'app-list-stage',
  templateUrl: './list-stage.component.html',
  styleUrls: ['./list-stage.component.scss']
})
export class ListStageComponent implements OnInit, OnChanges {

  @Input()
  stages: IStage[];

  @Input()
  states: IState[];

  @Input()
  loading: ILoading;

  @Output()
  stagesReorder$: EventEmitter<IStage[]> = new EventEmitter();

  @Output()
  stageSelected$: EventEmitter<IStage> = new EventEmitter();

  @Output()
  createStage$: EventEmitter<boolean> = new EventEmitter();

  buttonStatus: boolean;
  dataSource;
  displayedColumns: string[] = ['order', 'stage', 'state', 'dragndrop'];

  property: IProperty = STAGE_TEXT;

  constructor() { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.buttonStatus = true;
  }

  ngOnChanges() {
    const maxlengthStages = 7;
    this.dataSource = new MatTableDataSource(this.stages);
    if (this.stages) {
      this.stages.sort((a, b) => (a.position > b.position) ? 1 : (b.position > a.position) ? -1 : 0);
      this.buttonStatus = this.stages.length > maxlengthStages;
    } else {
      noop();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stages, event.previousIndex, event.currentIndex);
    let lastPosition = 0;
    this.stages.forEach(e => {
      e.position = this.stages.indexOf(e) + 1;
      if (e.position > lastPosition) {
        lastPosition = e.position;
      }
    });
    lastPosition++;
    this.stages.forEach(st => {
      if (st.expiry) {
        st.position = lastPosition;
        lastPosition++;
      }
    });
    this.stagesReorder$.emit(this.stages);
  }

  areThereStages(): boolean {
    return (this.stages && this.stages.length > 0) ? true : false;
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.stageList);
  }

  navigateToStage(stage: IStage): void {
    this.stageSelected$.emit(stage);
  }

  onCreateNewStage(): void {
    this.createStage$.emit(true);
  }

  getStatesOf(stageId: number): string {
    return (this.states) ? String(this.states.filter(s => s.codStage === stageId).length) : '0';
  }

  getStagesByExpire(expiry: boolean) {
    return this.stages?.filter(sta => sta.expiry === expiry);
  }

}
