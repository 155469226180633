import { ELoadingContent } from '../../../../shared/models/other/store/Loading.enum';
import { GetLoading } from '../../../../core/store/actions/general.actions';
import { selectSelectedSubtype } from '../../../../../app/core';
import { IAppState } from '../../../../core/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-product-container',
  templateUrl: './home-product-container.component.html'
})
export class HomeProductContainerComponent implements OnInit {

  subtype$ = this.store.pipe(select(selectSelectedSubtype));

  constructor(private store: Store<IAppState>) {
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.statistics }));
   }

  ngOnInit() {
  }

}
