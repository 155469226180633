export enum ELoadingContent {
    templateList = 'templateListServiceLoading',
    templateSelected = 'templateSelectedServiceLoading',
    stageList = 'stageListServiceLoading',
    taskList = 'taskListServiceLoading',
    stageSelected = 'stageSelectedServiceLoading',
    subtypeList = 'subtypeServiceLoading',
    subtypeSelected = 'subtypeSelectedServiceLoading',
    statistics = 'statistics',
    default = 'noLoading'
}
