<section class="stage-preview">
    <h5>Previsualización orden de las etapas</h5>
    <p>El siguiente esquema es una previsualización de las etapas, según el orden de notificación y exposición.</p>
    <app-loading *ngIf="componentIsLoading()"></app-loading>
    <section class="stage-previewed-container" *ngIf="!componentIsLoading()">
        <div class="stage-previewed-element" *ngFor="let stage of getStagesByExpire(false)">
            <div [className]="stage.notify ? 'circle active' : 'circle no-active'">
                <div class="line" *ngIf="!stageIsLast(stage)"></div>
                
            </div>
            <div class="content">
                <p class="position">Etapa {{stage.position}}</p>
                <p class="name">{{capitalizeString(stage.name)}}</p>
            </div>
        </div>
    </section>
</section>