import { IFolderSubtype } from '../../../shared/models/folder/FolderSubtype.interface';
import { ISubtype } from '../../../shared/models/subtype/Subtype.interface';
import { Action } from '@ngrx/store';

export enum ESubtypeActions {
    GetSubtypeAction = '[Subtype] Get Subtype',
    GetSubtypeSuccessAction = '[Subtype] Get Subtype Success',
    GetSubtypesAction = '[Subtype] Get Subtypes',
    GetSubtypesSuccessAction = '[Subtype] Get Subtypes Success'
}
export class GetSubtypes implements Action {
    public readonly type = ESubtypeActions.GetSubtypesAction;
    constructor() { }
}

export class GetSubtypesSuccess implements Action {
    public readonly type = ESubtypeActions.GetSubtypesSuccessAction;
    constructor(public payload: IFolderSubtype[]) { }
}

export class GetSubtype implements Action {
    public readonly type = ESubtypeActions.GetSubtypeAction;
    constructor(public payload: any) { }
}

export class GetSubtypeSuccess implements Action {
    public readonly type = ESubtypeActions.GetSubtypeSuccessAction;
    constructor(public payload: ISubtype) { }
}

export type SubtypeActions = GetSubtypes | GetSubtypesSuccess | GetSubtype | GetSubtypeSuccess;
