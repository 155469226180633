<section>
  <div class="bc-flex-title bc-flex bc-direction-row bc-align-items-center">
    <img src="{{property.option.iconActive}}" alt="Icon">
    <h3>{{property.label.title}}</h3>
  </div>
  <app-loading *ngIf="componentIsLoading()"></app-loading>
  <main *ngIf="!componentIsLoading()">
    <h4 class="sub-title-blue">{{stage?.name}}</h4>
    <div class="bc-flex-button bc-flex bc-direction-row bc-align-items-center bc-justify-content-flex-end">
      <p type="button" [ngClass]="{'plusHover' : !createTask}" class="btn-new-state" (click)="initCreateTask()"  ><em
        class="fas fa-plus-circle"></em> CREAR NUEVA TAREA
      </p>
    </div>
    <br>
    <div class="card-create" *ngIf="createTask">
      <app-task-form (cancelCreateTask$)="cancelCreate($event)" (createAnswerable$)="onEmitCreateAnswerable($event)"
        [codStage]="stage.codStage" [answerables]="answerables" [contacts]="contacts" [taskContacts]="taskContacts">
      </app-task-form>
    </div>
    <br>

    <app-no-content *ngIf="!areThereTasks()"></app-no-content>

    <ng-container *ngFor="let task of myTasks">
      <div class="option">
        <bc-icon (click)="edit(task)" size="sm" [style.color]="'#000'" aria-hidden="true"
          aria-label="chevron-right">pencil
        </bc-icon>
        <bc-icon (click)="delete(task)" size="sm" [style.color]="'#000'" aria-hidden="true"
          aria-label="chevron-right">trash-alt
        </bc-icon>
      </div>
      <bc-accordions-group class="bc-accordions-group">
        <bc-accordion>
          <bc-accordion-header title={{task.name}}>
          </bc-accordion-header>
          <bc-accordion-content>
            <br>
            <app-task-form [task]="task" [answerables]="answerables" [contacts]="contacts" [taskContacts]="taskContacts"
              (deleteTask$)="onEmitDeleteTask($event)" (createAnswerable$)="onEmitCreateAnswerable($event)">
            </app-task-form>
            <div class="bg bc-margin-top-3 bc-py-3 container-etapas">
              <div class="bc-col-12">
                <h6>Estados Asociados</h6>
                <div class="task-etapa bc-my-3">
                  <div *ngFor="let state of filterOfCodeTask(states, task.codTask)">
                    <span>{{state.name}}</span>
                  </div>
                </div>
                <span>Para asociar o desasociar los estados click <a href="../../components/">aquí</a></span>
              </div>
            </div>
          </bc-accordion-content>

        </bc-accordion>
      </bc-accordions-group>
      <br>
    </ng-container>

  </main>
</section>