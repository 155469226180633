import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IResponse } from '../../../shared/models/other/http/Response.interface';
import { GetLoading, GetLoadingSuccess } from '../../../core/store/actions/general.actions';
import { RESET_LOADING_BODY } from '../../../shared/constants/store.constants';
import { AnswerableService } from '../../../core/services/answerable.service';
import { IAppState } from '../../../core/store/state/app.state';
import { MatSnackBar } from '@angular/material/snack-bar';


import {
  EAnswerableActions,
  GetAnswerablesSuccess,
  GetAnswerables,
  SaveAnswerableSuccess,
  SaveAnswerable,
} from '../../../core/store/actions/answerable.actions';

import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AnswerableEffects {

  getAnswerables$ = createEffect(() => this.actions$.pipe(
    ofType<GetAnswerables>(EAnswerableActions.GetAnswerablesAction),
    switchMap(action => {
      return this.answerableService.getAnswerablesOfSubtype(action.payload);
    }),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoading(RESET_LOADING_BODY));
      return of(new GetAnswerablesSuccess(response.data));
    })
  ));

  saveAnswerable$ = createEffect(() => this.actions$.pipe(
    ofType<SaveAnswerable>(EAnswerableActions.SaveAnswerableAction),
    switchMap(payload => this.answerableService.saveAnswerable(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se creó el responsable '${response.data[0].name}'`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      this.store.dispatch(new GetAnswerables(response.data[0].codSubtype));
      return of(new SaveAnswerableSuccess(response.data));
    })
  ));



  constructor(
    private answerableService: AnswerableService,
    private actions$: Actions,
    private store: Store<IAppState>,
    private notification: MatSnackBar,
  ) { }
}
