<div *ngIf="!data.create" class="popup" id="popup">
    <em class="popup-icon fa fa-info-circle"></em>
    <h3>{{data.title}}</h3>
    <div mat-dialog-content>
        <p class="popup-message">{{data.message}}</p>
        <p class="popup-description">
            {{data.detail}}
        </p>
    </div>
    <div mat-dialog-actions *ngIf="data.singleButton">
        <button (click)="onButtonClick(true)" class="btn btn-primary" id="popup-confirm">Aceptar</button>
    </div>
    <div mat-dialog-actions *ngIf="!data.singleButton">
        <button cdkFocusInitial (click)="onButtonClick(false)" class="btn btn-secondary" id="popup-cancel">No</button>
        <button (click)="onButtonClick(true)" class="btn btn-primary" id="popup-confirm">Si</button>
    </div>
</div>

<div *ngIf="data.create" class="popupCreate" id="popup">
    <form *ngIf="nameFormGroup" [formGroup]="nameFormGroup">
        <h5 class="title-create">{{data.title}}</h5>

        <bc-form-field class="bc-form-field">
            <input bc-input typeInput="input-default" type="text"  formControlName="name" 
                [value]="nameFormGroup.value.name" required />
            <label>ingresa el nombre del responsable</label>
            <span></span>
        </bc-form-field>
    
        <div mat-dialog-actions *ngIf="!data.singleButton">
            <button cdkFocusInitial (click)="onButtonClick(false)" class="btn btn-secondary" id="popup-cancel">
                CANCELAR</button>
            <button (click)="onCreateButtonClick()" [disabled]="nameFormGroup.invalid"  class="btn btn-primary"
                id="popup-confirm">GUARDAR</button>
        </div>
    </form>
</div>
