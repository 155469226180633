import { IContactState } from '../../store/state/contact.state';
import {initialContactState} from '../../store/state/contact.state';
import { ContactActions, EContactActions } from'../../store/actions/contact.actions';

export function contactReducer(
    state = initialContactState,
    action: ContactActions
): IContactState {
    switch (action.type) {
        case EContactActions.GetContactsSuccessAction: {
            return {
                ...state,
                contacts: action.payload
            };
        }
        case EContactActions.GetTaskContactsSuccessAction: {
            return {
                ...state,
                tasksContacts: action.payload
            };
        }
        default:
            return state;
    }
}
