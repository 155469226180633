import { ITaskContacts } from '../../../shared/models/taskContacts/TaskContacts.interface';
import { IContact } from '../../../shared/models/contact/Contact.interface';
import { Action } from '@ngrx/store';

export enum EContactActions {
    GetContactsAction = '[Contact] Get Contacts',
    GetContactsSuccessAction = '[Contact] Get Contacts Success',
    GetTaskContactsAction = '[TaskContacts] Get TaskContacts',
    GetTaskContactsSuccessAction = '[TaskContacts] Get TaskContacts Success',
}

export class GetContacts implements Action {
    public readonly type = EContactActions.GetContactsAction;
    constructor(public payload: string) { }
}

export class GetContactsSuccess implements Action {
    public readonly type = EContactActions.GetContactsSuccessAction;
    constructor(public payload: IContact[]) { }
}

export class GetTaskContacts implements Action {
    public readonly type = EContactActions.GetTaskContactsAction;
    constructor(public payload: string) { }
}

export class GetTaskContactsSuccess implements Action {
    public readonly type = EContactActions.GetTaskContactsSuccessAction;
    constructor(public payload: ITaskContacts[]) { }
}

export type ContactActions = GetContacts | GetContactsSuccess | GetTaskContacts | GetTaskContactsSuccess ;
