import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ITask } from '../../../../../../shared/models/task/Task.interface';
import { IContact } from '../../../../../../shared/models/contact/Contact.interface';
import { IAnswerable } from '../../../../../../shared/models/answerable/Answerable.interface';
import { ALPHANUMERIC_UTF8_REGEX } from '../../../../../../shared/constants/regex.constants';
import { PopupComponent } from '../../../../../../shared/components/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { noop } from 'rxjs';
import { ITaskContacts } from '../../../../../../shared/models/taskContacts/TaskContacts.interface';
import { Task } from '../../../../../../shared/models/task/Task';
import { CreateTask, UpdateTask } from '../../../../../../core/store/actions/task.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../../core/store/state/app.state';

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss']
})
export class TaskFormComponent implements OnInit, OnChanges {

  @Input() codStage?: number;
  @Input() task?: ITask;
  @Input() answerables?: IAnswerable[];
  @Input() contacts?: IContact[];
  @Input() taskContacts?: ITaskContacts[];
  @Output() deleteTask$: EventEmitter<ITask> = new EventEmitter();
  @Output() updateTask$: EventEmitter<ITask> = new EventEmitter();
  @Output() createAnswerable$: EventEmitter<string> = new EventEmitter();
  @Output() cancelCreateTask$: EventEmitter<boolean> = new EventEmitter();

  taskFormGroup: FormGroup;
  answerableSelected: number;
  contactsSelected: number[];
  answerablesValues: {
    valuesIcon: { label: string,value: number, icon?: string }[],
    placeholder: string,
    textBelow: string,
  };

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private store: Store<IAppState>) { }

  ngOnInit(): void {
    // this.answerablesValues.isDisabled = "enabled"
   }

  ngOnChanges() {
    this.initForms();
  }

  createAnswerable() {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '320px',
      data: {
        title: 'Crear nuevo responsable', message: `ingresa el nombre del responsable`,
        detail: '', action: false,
        singleButton: false,
        create: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.createAnswerable$.emit(result);
        console.log(this.answerables.filter(a => a.name === result).map(a => a.name));
      } else {
        this.answerableChange(this.task.codAnswerable);
        this.taskFormGroup.patchValue({ codAnswerable: this.task.codAnswerable });
      }
    });
  }
  answerableChange(cod: any) {
    this.answerableSelected = cod;
  }

  // Utils
  taskExists(): boolean {
    return this.task ? true : false;
  }

  initForms() {
    const tB = this.taskExists();
    const maxlengthName = 100;
    const maxlengthDescription = 650;
    this.taskFormGroup = this.formBuilder.group({
      name: new FormControl(tB ? this.task.name : '', [
        Validators.required,
        Validators.maxLength(maxlengthName),
        Validators.pattern(ALPHANUMERIC_UTF8_REGEX),
      ]),
      description: new FormControl(tB ? this.task.description : '', [
        Validators.required,
        Validators.maxLength(maxlengthDescription),
      ]),
      codAnswerable: new FormControl(tB ? this.task.codAnswerable : '', [
        Validators.required
      ]),
      taskContacts: new FormControl(
        this.filterOfCodStage(this.taskContacts), [
        Validators.required
      ])
    });
    if (tB) { this.taskFormGroup.disable(); }
    this.answerablesValues = {
      valuesIcon: this.answerables.map(a => {
        return { value: a.codResponsible, label: a.name, icon: '' };
      }),
      placeholder: 'Responsable',
      textBelow: '',
    };
    this.answerablesValues.valuesIcon.push({ value: 0, label: 'Agragar Responsable', icon: 'lock-alt' });
  }

  onCreate() {
    if (this.taskFormGroup.status === 'VALID') {
      const auxForm = this.taskFormGroup.value;
      const jsonTask: Task = {
        codStage: this.codStage,
        codAnswerable: auxForm.codAnswerable,
        name: auxForm.name,
        description: auxForm.description,
        codContacts: auxForm.taskContacts
      };
      this.store.dispatch(new CreateTask(jsonTask));
    }
  }

  onCancelCreate() {
    this.cancelCreateTask$.emit(false);
  }

  onEdit() {
    if (this.taskFormGroup.disabled) {
      this.taskFormGroup.enable();
    } else {
      this.taskFormGroup.disable();
      this.initForms();
    }
  }

  onDelete(): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '320px',
      data: {
        title: '¡Espera!', message: `¿Estás seguro que quieres eliminar la tarea "${this.task.name}" ?`,
        detail: '', action: false,
        singleButton: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteTask$.emit(this.task);
      } else {
        noop();
      }
    });
  }

  filterOfCodStage(array: ITaskContacts[]): number[] {
    if (array !== undefined && array !== null) {
      try {
        return array.filter(
          f => f.codTask === this.task.codTask)[0].codContacts;
      } catch (TypeError) {
        return [];
      }
    } else {
      return [];
    }
  }

  saveUpdate() {
    if (this.taskFormGroup.status === 'VALID') {
      const auxForm = this.taskFormGroup.value;
      const jsonTask: Task = {
        codStage: this.task.codStage,
        codAnswerable: auxForm.codAnswerable,
        name: auxForm.name,
        description: auxForm.description,
        codContacts: auxForm.taskContacts
      };
      this.store.dispatch(new UpdateTask(this.task.codTask, jsonTask));
    }
  }
}
