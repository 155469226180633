import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState, selectLoading } from '../../../../../../../app/core';
import { selectStageList } from '../../../../../../../app/core/store/selectors/stage.selectors';

@Component({
  selector: 'app-stages-order-preview-container',
  templateUrl: './stages-order-preview-container.component.html'
})
export class StagesOrderPreviewContainerComponent implements OnInit {

  stages$ = this.store.pipe(select(selectStageList));
  loading$ = this.store.pipe(select(selectLoading));

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
  }

}
