import { IProperty } from '../../../../../../shared/models/other/property/Property.interface';
import { TASK_TEXT } from './../../../../../../shared/constants/text.constants';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { isComponentLoading } from '../../../../../../shared/constants/store.constants';
import { noop } from 'rxjs';
import { ILoading } from '../../../../../../shared/models/other/store/Loading.interface';
import { IStage } from '../../../../../../shared/models/stage/Stage.interface';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ITask } from '../../../../../../../app/shared/models/task/Task.interface';
import { Stage } from '../../../../../../../app/shared/models/stage/Stage';



@Component({
  selector: 'app-list-task',
  templateUrl: './list-task.component.html',
  styleUrls: ['./list-task.component.scss']
})
export class ListTaskComponent implements OnInit, OnChanges {

  @Input() stages: IStage[];
  @Input() tasks: ITask[];
  @Input() loading: ILoading;
  @Output() stageSelected$: EventEmitter<IStage> = new EventEmitter();

  public property: IProperty = TASK_TEXT;
  public listStages;
  public headersTable: any[] = [
    { type: 'center', name: 'Orden', showOnXs: false, sortable: false, showOnSm: false },
    { type: 'left', name: 'Nombre de la Etapa', showOnXs: false, sortable: false, showOnSm: false },
    { type: 'center', name: 'N° Tareas Asociadas', showOnXs: false, sortable: false, showOnSm: false },
  ];

  constructor() { }

  ngOnInit() {  }

  ngOnChanges() {
    if (this.stages) {
      [...this.stages].sort((a, b) => (a.position > b.position) ? 1 : (b.position > a.position) ? -1 : 0);
    } else {
      noop();
    }
    this.listStages = this.stages;
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.stageList);
  }

  areThereStages(): boolean {
    return (this.stages && this.stages.length > 0) ? true : false;
  }

  getTasksOf(stageId: number): string {
    return (this.tasks) ? String(this.tasks.filter(s => s.codStage === stageId).length) : '0';
  }

  navigateToTask(stage: Stage) {
    this.stageSelected$.emit(stage);
  }

}
