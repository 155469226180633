/* Angular */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* App */
import { AppMaterialModule } from '../../../app/app-material.module';
import { SharedModule } from '../../shared/components/shared.module';

/* * This * */
import { TemplateDashboardModule } from '../../modules/dashboard/modules/template/template.module';
import { DashboardRoutingModule } from '../../modules/dashboard/dashboard-routing.module';
import { HomeContainerComponent } from '../../modules/dashboard/containers/home-container/home-container.component';
import { StageDashboardModule } from './modules/stage/stage.module';
import { TaskDashboardModule } from './modules/task/task.module';

/* Common components */
import { NavbarComponent } from '../../modules/dashboard/components/navbar/navbar.component';
import { HomeComponent } from '../../modules/dashboard/pages/home/home.component';

/* Product */
import { ProductListComponent } from '../../modules/dashboard/components/product-list/product-list.component';
import { ProductListContainerComponent } from '../../modules/dashboard/containers/product-list-container/product-list-container.component';
import { SidebarComponent } from '../../modules/dashboard/components/sidebar/sidebar.component';
import { SidebarContainerComponent } from '../../modules/dashboard/containers/sidebar-container/sidebar-container.component';
import { HomeProductComponent } from './pages/home-product/home-product.component';
import { HomeProductContainerComponent } from './containers/home-product-container/home-product-container.component';
import { HomeAdminComponent } from './pages/home-admin/home-admin.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductCardContainerComponent } from './containers/product-card-container/product-card-container.component';

// Libreria de diseño bancolombia
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';



@NgModule({
    imports: [
        CommonModule,
        DashboardRoutingModule,
        AppMaterialModule,
        TemplateDashboardModule,
        StageDashboardModule,
        TaskDashboardModule,
        SharedModule,
        BcButtonModule,
        BcIconModule,
        BcAlertModule,
        BcInputSelectModule
    ],
    declarations: [
        NavbarComponent,
        HomeComponent,
        HomeContainerComponent,
        ProductListComponent,
        ProductListContainerComponent,
        SidebarComponent,
        SidebarContainerComponent,
        HomeProductComponent,
        HomeProductContainerComponent,
        HomeAdminComponent,
        ProductCardComponent,
        ProductCardContainerComponent,
    ]
})

export class DashboardModule { }
