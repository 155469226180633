import { IProperty } from '../../../../shared/models/other/property/Property.interface';
import { STAGE_TEXT, TEMPLATE_TEXT, TASK_TEXT, INDEX_TEXT } from './../../../../shared/constants/text.constants';
import { ActivatedRoute } from '@angular/router';
import { ELoadingContent } from '../../../../shared/models/other/store/Loading.enum';
import { isComponentLoading } from '../../../../shared/constants/store.constants';
import { ILoading } from '../../../../shared/models/other/store/Loading.interface';
import { IFolderSubtype } from '../../../../shared/models/folder/FolderSubtype.interface';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input()
  subtypes: IFolderSubtype[];

  @Input()
  loading: ILoading;

  @Output()
  subtypeSelected$: EventEmitter<string> = new EventEmitter();

  property: IProperty;
  description: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    /* tslint:disable:no-string-literal */
    if ((this.activatedRoute.snapshot['_routerState'].url).includes('etapas')) {
      this.property = STAGE_TEXT;
    } else if ((this.activatedRoute.snapshot['_routerState'].url).includes('plantillas')) {
      this.property = TEMPLATE_TEXT;
    } else if ((this.activatedRoute.snapshot['_routerState'].url).includes('tareas')) {
      this.property = TASK_TEXT;
    } else {
      this.property = INDEX_TEXT;
    }
  }

  navigateToSubtype(subtype: string): void {
    this.subtypeSelected$.emit(`${subtype}/${this.property.routes.list}`);
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.subtypeList);
  }

}
