<div class="home-product">
  <section class="home-product-description">
    <h1>Bienvenido</h1>
    <h3>Al tracking de {{getSubtypeName()}}.</h3>
    <p>Estamos construyendo experiencias superiores para el seguimiento de las solicitudes de nuestros clientes.
    </p>
    <section class="stage-previewed-container">
      <div class="stage-previewed-element">
        <div class="active">
          <div class="line"></div>
          <img src="/assets/icons/on/bombilla.svg" alt="bombilla " />
        </div>
        <div class="content">
          <p class="position">Idear</p>
        </div>
      </div>
      <div class="stage-previewed-element">
        <div class="no-active">
          <div class="line"></div>
          <img src="/assets/icons/off/web.svg" alt="web" />
        </div>
        <div class="content">
          <p class="position">Construir</p>
        </div>
      </div>
      <div class="stage-previewed-element">
        <div class="no-active">
          <img src="/assets/icons/off/notificacion.svg" alt="campana" />
        </div>
        <div class="content">
          <p class="position">Notificar</p>
        </div>
      </div>
    </section>
  </section>
  <img src="assets/images/maintenance.svg" alt="En construcción" class="img-vector" />
</div>
