import { PopupComponent } from '../../../../../../../../../app/shared/components/popup/popup.component';
import { noop } from 'rxjs';
import { capitalizeString } from '../../../../../../../../../app/shared/constants/formats.constants';
import { ALPHANUMERIC_UTF8_REGEX } from '../../../../../../../../../app/shared/constants/regex.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IStage } from '../../../../../../../../../app/shared/models/stage/Stage.interface';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { IState } from '../../../../../../../../../app/shared/models/state/State.interface';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

const maxlengthName = 45;
const validators = [
  Validators.required,
  Validators.maxLength(maxlengthName),
  Validators.pattern(ALPHANUMERIC_UTF8_REGEX)];

@Component({
  selector: 'app-state-form',
  templateUrl: './state-form.component.html',
  styleUrls: ['./state-form.component.scss']
})
export class StateFormComponent implements OnInit, OnChanges {

  @Input()
  states: IState[];

  @Input()
  statesSubtype: IState[];

  @Input()
  stage: IStage;

  @Input()
  stages: IStage[];

  @Output()
  saveState$: EventEmitter<IState> = new EventEmitter();

  @Output()
  deleteState$: EventEmitter<IState> = new EventEmitter();


  newStateForm: FormGroup;
  editStateForm: FormGroup;
  statesArray: FormArray;

  isFormGroupLoaded: boolean;
  isInEditingMode: boolean;
  isCreatingState: boolean;
  stateIdEditing: number;
  resetVariablesOnInit: boolean;
  alreadySubmitted: boolean;
  stateNameBeforeEditing: string;
  newStateJson: IState;

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initVariables();
    this.initNewStateForm();
  }

  ngOnChanges() {
    if (this.states) {
      this.initVariables();
      this.initNewStateForm();
      this.editStateForm = new FormGroup({
        statesArray: new FormArray([])
      });
      this.statesArray = this.editStateForm.get('statesArray') as FormArray;
      this.states.forEach(element => this.statesArray.push(this.initStateInEditForm(element)));
      this.statesArray.disable();
    } else {
      noop();
    }
  }

  capitalizeString(name: string): string {
    return capitalizeString(name);
  }

  initStateInEditForm(state: IState): FormGroup {
    return this.formBuilder.group({
      codState: state.codState,
      codSubtype: state.codSubtype,
      codStage: state.codStage,
      name: [state.name, validators]
    });
  }

  initVariables() {
    this.isFormGroupLoaded = false;
    this.alreadySubmitted = false;
    this.stateNameBeforeEditing = '';
    this.initResetCreatingMode();
  }

  initResetCreatingMode() {
    if (this.newStateForm) {
      this.newStateForm.reset();
    } else {
      noop();
    }
    this.alreadySubmitted = false;
    this.isCreatingState = false;
  }

  initNewStateForm() {
    this.newStateForm = this.formBuilder.group({
      stateName: [undefined, validators],
    });
  }

  toggleEditingMode(state: IState): void {
    this.stateIdEditing = state.codState;
    this.initResetCreatingMode();
    this.statesArray.controls.find(c => c.value.codState === state.codState).enable();
    this.stateNameBeforeEditing = state.name;
  }

  onCancelEditMode(item) {
    if (item) {
      this.stateIdEditing = -1;
      this.statesArray.clear();
      this.states.forEach(element => this.statesArray.push(this.initStateInEditForm(element)));
      this.statesArray.disable();
      item.value.name = this.stateNameBeforeEditing;
      item.controls.name.errors = null;
    } else {
      noop();
    }
  }

  isAvailable(stateId: number): boolean {
    return this.statesArray.controls.find(c => c.value.codState === stateId).enabled;
  }

  onAddNewState(): void {
    this.statesArray.controls.forEach((formGroup, _) => {
      if (formGroup.enabled) {
        formGroup.get('name').setErrors(null);
        formGroup.get('name').setValue(this.stateNameBeforeEditing);
        formGroup.disable();
      }
    });
    this.isCreatingState = true;
  }

  onEditState(item: any): void {
    if (this.editStateForm.invalid) {
      return;
    } else {
      this.onSaveState(item.value);
    }
  }

  onCreateState(): void {
    this.alreadySubmitted = true;
    if (this.newStateForm.invalid) {
      return;
    } else {
      this.onSaveState({
        codState: -1,
        codSubtype: this.stage.codSubtype,
        codStage: this.stage.codStage,
        name: this.newStateForm.value.stateName,
        codTask: null
      });
    }
  }

  onReplaceState(filterExisting: IState, newState: IState): void {
    if (filterExisting.codStage === this.stage.codStage) {
      this.snackBar.open(`Error. El estado ya existe en esta etapa, por favor ingrese otro nombre.`, 'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-error'
      });
    } else {
      this.onConfirmReplaceState(filterExisting, newState);
    }
  }

  onSaveState(state: IState): void {
    if (this.statesSubtype) {
      const filterExisting = this.statesSubtype.find(element => this.formatStateName(element.name) === this.formatStateName(state.name));
      if (filterExisting) {
        this.onReplaceState(filterExisting, state);
      } else {
        this.alreadySubmitted = false;
        this.onCancelEditMode(undefined);
        this.saveState$.emit(state);
      }
    } else {
      this.snackBar.open(`Error. No fue posible validar si el estado está en otra etapa. Inténtelo más tarde`, 'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-error'
      });
    }
  }

  formatStateName(name: string): string {
    return name.toLowerCase().split(' ').join('');
  }

  onConfirmReplaceState(lastState: IState, newState: IState) {
    const nameLastStage = this.stages.find(s => s.codStage === lastState.codStage).name;
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '320px',
      data: {
        title: '¡Espera!', message: `Este estado ${lastState.name} ya se encuentra asociado a la etapa ${nameLastStage}`,
        detail: `Recuerda que al confirmar, el estado quedará asociado únicamente a la etapa ${this.stage.name}`,
        action: false,
        singleButton: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        newState.codState = lastState.codState;
        if (this.stateIdEditing !== -1) {
          this.deleteState$.emit({ codState: this.stateIdEditing, codStage: null, codSubtype: null, name: null, codTask: null });
        } else {
          noop();
        }
        this.saveState$.emit(newState);
      } else {
        noop();
      }
    });
  }

  onDeleteState(state: IState): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '320px',
      data: {
        title: '¡Espera!', message: `¿Estás seguro que quieres eliminar el estado ${state.name} asociado a la etapa ${this.stage.name}?`,
        detail: '', action: false,
        singleButton: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteState$.emit(state);
      } else {
        noop();
      }
    });
  }

  areThereEnoughStates(): boolean {
    return (this.states) ? (this.states.length > 0) : false;
  }
}
