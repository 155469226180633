import { IProperty } from '../../../../../../shared/models/other/property/Property.interface';
import { TEMPLATE_TEXT } from './../../../../../../shared/constants/text.constants';
import { MatSortModule } from '@angular/material/sort';
import { ILoading } from '../../../../../../shared/models/other/store/Loading.interface';
import { ITemplate } from '../../../../../../shared/models/template/Template.interface';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { isComponentLoading } from '../../../../../../shared/constants/store.constants';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IStage } from '../../../../../../../app/shared/models/stage/Stage.interface';


@Component({
  selector: 'app-list-template',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.scss']
})
export class ListTemplateComponent implements OnInit, OnChanges {

  @Input()
  templates: ITemplate[];

  @Input()
  stages: IStage[];

  @Input()
  loading: ILoading;

  @Output()
  templateSelected$: EventEmitter<ITemplate> = new EventEmitter();

  displayedColumns: string[] = ['state', 'stage', 'sms', 'email'];
  dataSource;

  property: IProperty = TEMPLATE_TEXT;

  @ViewChild(MatSortModule, { static: true }) sort: MatSort;

  constructor() { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.templates);
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.templates);
    if (this.dataSource?.data && this.stages.length) {
      let lastPosition = 0;
      this.stages.forEach(e => {
        if (e.position > lastPosition) {
          lastPosition = e.position;
        }
      });
      lastPosition++;
      this.stages.forEach(st => {
        if (st.expiry) {
          st.position = lastPosition;
          lastPosition++;
        }
      });
      this.dataSource.data.forEach(data => {
        const stage = this.stages.find(st => st.codStage === data.codStage);
        data.position = stage.position;
      });
      this.dataSource.data.sort((a, b) => (a.position > b.position) ? 1 : (b.position > a.position) ? -1 : 0);
    }
  }

  componentIsLoading(): boolean {
    return isComponentLoading(this.loading, ELoadingContent.templateSelected);
  }

  isElementDisplayed(element: boolean): boolean {
    return element;
  }

  areThereTemplates(): boolean {
    return (this.templates && this.templates.length > 0) ? true : false;
  }

  navigateToTemplate(template: ITemplate): void {
    this.templateSelected$.emit(template);
  }
}
