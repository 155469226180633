import { IGeneralState, initialGeneralState } from '../../../core/store/state/general.state';
import { GeneralActions, EGeneralActions } from '../../../core/store/actions/general.actions';

export function generalReducer(
    state = initialGeneralState,
    action: GeneralActions
): IGeneralState {
    if (action.type === EGeneralActions.GetLoadingSuccessAction) {
        return {
            ...state,
            loading: action.payload
        };
    } else {
        return state;
    }
}
