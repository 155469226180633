import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public username: string = "";

  constructor( private authService: MsalService, private router: Router, private http: HttpClient ) {
  }

  ngOnInit() {
    this.loadUsername()
  }

  onLogout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }

/**
 * Obtiene el nombre del usuario logueado y lo almacena en el localStorage.
 */
  loadUsername() {
    if (!localStorage.getItem('name')) {
      this.name();
    }

    if(localStorage.getItem('name')) {
      this.username = localStorage.getItem('name')
    }else {
      this.username = 'No disponible'
    }
  };

    /**
   * Obtiene el nombre del usuario logueado y lo almacena en el localStorage.
   * @return retorna la informacion completa del usuario logueado
   */
    name() {
      localStorage.setItem('name', this.authService.instance.getAllAccounts()[0].name)
    }

}
