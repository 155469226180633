import { selectLoading } from '../../../../../../../app/core/store/selectors/general.selectors';
import { selectStageList, selectStateList } from '../../../../../../core/store/selectors/stage.selectors';
import { selectTaskList} from '../../../../../../core/store/selectors/task.selectors';
import { selectAnswerableList} from '../../../../../../core/store/selectors/answerable.selector';
import { selectContactList, selectTaskContacstList } from '../../../../../../core/store/selectors/contact.selector';
import { ELoadingContent } from '../../../../../../shared/models/other/store/Loading.enum';
import { GetLoading } from '../../../../../../core/store/actions/general.actions';
import { GetStages, GetStates } from '../../../../../../core/store/actions/stage.actions';
import { GetAnswerables, SaveAnswerable } from '../../../../../../core/store/actions/answerable.actions';
import { GetContacts, GetTaskContacts } from '../../../../../../core/store/actions/contact.actions';
import { IAppState } from '../../../../../../core/store/state/app.state';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { DeleteTask, GetTasks } from '../../../../../../../app/core/store/actions/task.actions';
import { ITask } from '../../../../../../../app/shared/models/task/Task.interface';
import { IAnswerable } from '../../../../../../../app/shared/models/answerable/Answerable.interface';


@Component({
  selector: 'app-list-task-form-container',
  templateUrl: './list-task-form-container.component.html',
  styleUrls: ['./list-task-form-container.component.scss']
})
export class ListTaskFormContainerComponent implements OnInit {

  tasks$ = this.store.pipe(select(selectTaskList));
  answerables$ = this.store.pipe(select(selectAnswerableList));
  stages$ = this.store.pipe(select(selectStageList));
  contacts$ = this.store.pipe(select(selectContactList));
  taskContacts$ = this.store.pipe(select(selectTaskContacstList));
  loading$ = this.store.pipe(select(selectLoading));
  states$ = this.store.pipe(select(selectStateList));

  constructor(private store: Store<IAppState>, private activatedRoute: ActivatedRoute) { }


  ngOnInit() {
    const subtypeId = this.activatedRoute.parent.snapshot.params['id'];
    const stageId = this.activatedRoute.snapshot.url.join('/').split('/')[1];
    this.store.dispatch(new GetTasks(subtypeId));
    this.store.dispatch(new GetStages(subtypeId));
    this.store.dispatch(new GetAnswerables(subtypeId));
    this.store.dispatch(new GetContacts(subtypeId));
    this.store.dispatch(new GetTaskContacts(stageId));
    this.store.dispatch(new GetLoading({ state: true, content: ELoadingContent.taskList }));
    this.store.dispatch( new GetStates(subtypeId));
  }


  onDeleteTask(task: ITask) {
    this.store.dispatch(new DeleteTask(task));
  }

  onCreateAnswerable(answerableName: string) {
    const answerable: IAnswerable = {
      codResponsible: 0,
      codSubtype: this.activatedRoute.parent.snapshot.params['id'],
      name: answerableName
    };
    this.store.dispatch(new SaveAnswerable(answerable));
  }

}
