<nav class="navbar">
    <a href="/panel"><img src="/assets/images/logo-bancolombia-navbar.png" width="120" height="28" class="logo-nav"
            alt="Bancolombia logo"></a>
    <div class="user-info">
        <div>{{username}}</div>
        <bc-icon aria-hidden="true" aria-label="Bancolombia" role="img" class="bc-icon bc-lg ">
            user-check
        </bc-icon>
    </div> 
</nav>
