export const OPTIONS_CONST = {
    meta: {
        _messageId: 'Tracking administrative module',
        _applicationId: 'Tracking constant',
        _requestDate: `${Date.now()}`
    },
    data: [
        {
            codOption: 1,
            featured: true,
            name: 'Inicio',
            icon: '/assets/icons/off/casa.svg',
            iconActive: '/assets/icons/on/casa.svg',
        },
        {
            codOption: 2,
            featured: true,
            name: 'Etapas / Estados',
            icon: '/assets/icons/off/check.svg',
            iconActive: '/assets/icons/on/check.svg',
        },
        {
            codOption: 3,
            featured: true,
            name: 'Plantillas',
            icon: '/assets/icons/off/documento.svg',
            iconActive: '/assets/icons/on/documento.svg',
        },
        {
            codOption: 4,
            featured: true,
            name: 'Tareas',
            icon: '/assets/icons/off/objetivo.svg',
            iconActive: '/assets/icons/on/objetivo.svg',
        }
    ]
};
