import { IResponse } from '../../shared/models/other/http/Response.interface';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubtypeService {

  constructor(private http: HttpClient) { }

  getAllSubtypes(): Observable<IResponse> {
    return this.http.get<IResponse>(`${environment.apiEndPoint}/subtypes/`);
  }

  getSubtypeById(id: string): Observable<IResponse> {
    return this.http.get<IResponse>(`${environment.apiEndPoint}/subtypes/${id}`);
  }

}
