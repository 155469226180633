import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../shared/models/other/http/Response.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ITask } from '../../shared/models/task/Task.interface';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient) { }

  getTasksOfSubtype(subtypeId: string): Observable<IResponse> {
    return this.http.get<IResponse>
      (`${environment.apiEndPoint}/tasks/subtype/${subtypeId}`);
  }

  delete(task: ITask): Observable<IResponse> {
    return this.http.delete<IResponse>(`${environment.apiEndPoint}/tasks/${task.codTask}`);
  }

  createTask(task: ITask): Observable<IResponse> {
    return this.http.post<IResponse>(`${environment.apiEndPoint}/tasks`, task);
  }

  updateTask(idTask: number, task: ITask): Observable<IResponse> {
    return this.http.put<IResponse>(`${environment.apiEndPoint}/tasks/${idTask}`, task);
  }

}
