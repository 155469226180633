import { IField } from '../../../../../../../../shared/models/field/Field.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-template-info-box',
  templateUrl: './template-info-box.component.html',
  styleUrls: ['./template-info-box.component.scss']
})
export class TemplateInfoBoxComponent implements OnInit {

  @Input()
  fields: IField[];

  visible: boolean;

  constructor() { }

  ngOnInit() {
    this.visible = true;
  }

  onSwitchVisible(): void {
    this.visible = !this.visible;
  }

}
