<form *ngIf="taskFormGroup" [formGroup]="taskFormGroup">
    <div class="bc-container label-class">
        <div class="bc-row">
            <div class="bc-col-6">
                <bc-form-field>
                    <input class="style-bc-form-field" formControlName="name" [value]="taskFormGroup.value.name"
                           bc-input typeInput="input-default" type="text" value="" required />
                    <label>Nombre de la tarea</label>
                    <span></span>
                </bc-form-field>
                <div *ngIf="taskFormGroup.controls.name.errors">
                    <app-form-error-box *ngIf="taskFormGroup.controls.name.errors.pattern"
                        message="Verifique que el nombre sea alfanumérico">
                    </app-form-error-box>
                    <app-form-error-box *ngIf="taskFormGroup.controls.name.errors.required"
                        message="Por favor escriba un nombre">
                    </app-form-error-box>
                    <app-form-error-box *ngIf="taskFormGroup.controls.name.errors.maxlength"
                        message="Por favor no exceda el límite de caracteres. Límite:
                        {{taskFormGroup.controls.name.errors.maxlength.actualLength}} /
                        {{taskFormGroup.controls.name.errors.maxlength.requiredLength}}">
                    </app-form-error-box>
                </div>
            </div>
            <div class="bc-col-6">
                <bc-input-select *ngIf="false"  [values]="answerablesValues.valuesIcon"
                    [label]="answerablesValues.placeholder" [span]="answerablesValues.textBelow"
                     (valueOutput)="answerableChange($event)"
                    [status]="'enabled'">
                </bc-input-select>

                <mat-form-field appearance="fill">
                    <mat-label>Responsable</mat-label>
                    <mat-select formControlName="codAnswerable" [value]="taskFormGroup.value.codResponsible">
                      <mat-option *ngFor="let ans of answerables"
                          (selectionChange)="answerableChange(ans.codResponsible)" [value]="ans.codResponsible">
                        {{ans.name}}
                      </mat-option>
                      <mat-option (click)="createAnswerable()">
                        Crear nuevo responsable<bc-icon size="xs" [style.float]="'right'"
                            [style.color]="'#000'" aria-hidden="true" aria-label="chevron-right">plus-circle
                            </bc-icon>
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                 
                <div *ngIf="taskFormGroup.controls.codAnswerable.errors">
                    <app-form-error-box *ngIf="taskFormGroup.controls.codAnswerable.errors.required"
                        message="Por favor seleccionar un responsable">
                    </app-form-error-box>
                </div>
            </div>
        </div>
        <div class="bc-row-12">
            <bc-form-field>
                <textarea bc-input formControlName="description" [value]="taskFormGroup.value.description"
                          typeInput="text-area" type="text" placeholder="Ingresa una descripción..." value="" required>
                </textarea>
                <label for="">Descripción</label>
                <span>
                    <div *ngIf="taskFormGroup.controls.description.errors">
                        <app-form-error-box *ngIf="taskFormGroup.controls.description.errors.pattern"
                            message="Verifique que la descripción sea alfanumérica">
                        </app-form-error-box>
                        <app-form-error-box *ngIf="taskFormGroup.controls.description.errors.required"
                            message="Por favor escriba una descripción">
                        </app-form-error-box>
                        <app-form-error-box *ngIf="taskFormGroup.controls.description.errors.maxlength"
                            message="Por favor no exceda el límite de caracteres. Límite:
                            {{taskFormGroup.controls.description.errors.maxlength.actualLength}} /
                            {{taskFormGroup.controls.description.errors.maxlength.requiredLength}}">
                        </app-form-error-box>
                    </div>
                </span>
            </bc-form-field>
        </div>
        <div class="bc-row-12">

            <mat-form-field appearance="fill">
                <mat-label>Contactos</mat-label>
                <mat-select formControlName="taskContacts"  multiple>
                    <mat-option *ngFor="let contact of contacts" [value]="contact.codContact">
                        {{contact.name}} ( {{contact.phone}} - {{contact.email}} ) 
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="taskFormGroup.controls.taskContacts.errors">
                <app-form-error-box *ngIf="taskFormGroup.controls.taskContacts.errors.required"
                   message="Por favor escoja los contactos pertenecientes a la tarea">
                </app-form-error-box>
            </div>
        </div>
        <br>
        <div class="bc-row bc-flex bc-justify-content-center">
            <div class="bc-col-3">
                <button *ngIf="taskExists()" (click)="onEdit()" [disabled]="this.taskFormGroup.disabled"
                    aria-label="Boton" bc-button sizeButton="small" typeButton="secondary">CANCELAR</button>
                <button *ngIf="!taskExists()" (click)="onCancelCreate()" aria-label="Boton" bc-button sizeButton="small"
                    typeButton="secondary">CANCELAR</button>
            </div>
            <div class="bc-col-3">
                <button *ngIf="!taskExists()" (click)="onCreate()" [disabled]="taskFormGroup.invalid"
                    aria-label="Boton" bc-button sizeButton="small">CREAR</button>
                <button *ngIf="!this.taskFormGroup.disabled && taskExists()" (click)="saveUpdate()"
                    [disabled]="taskFormGroup.invalid" aria-label="Boton" bc-button sizeButton="small">GUARDAR</button>
                <button *ngIf="this.taskFormGroup.disabled" (click)="onEdit()" aria-label="Boton"
                bc-button sizeButton="small">EDITAR</button>
            </div>  
        </div>
    </div>
</form>

