import { ILoading } from '../../shared/models/other/store/Loading.interface';
import { ELoadingContent } from '../../shared/models/other/store/Loading.enum';

export const RESET_LOADING_BODY = {
    state: false,
    content: ELoadingContent.default
};

export const isComponentLoading = (loading: ILoading, service: string): boolean => {
    if (!loading) {
        return false;
    } else if (loading.content === service) {
        return loading.state;
    } else {
        return false;
    }
};
