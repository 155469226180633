import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IResponse } from '../../../shared/models/other/http/Response.interface';
import { GetLoading, GetLoadingSuccess } from '../../../core/store/actions/general.actions';
import { RESET_LOADING_BODY } from '../../../shared/constants/store.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaskService } from '../../../core/services/task.service';
import { IAppState } from '../../../core/store/state/app.state';

import {
  ETaskActions,
  GetTasks,
  GetTasksOfStageSuccess,
  DeleteTask,
  DeleteTaskSuccess,
  UpdateTask,
  UpdateTaskSuccess,
  CreateTask,
  CreateTaskSuccess
} from '../../../core/store/actions/task.actions';

import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RouterBack } from '../actions/router.actions';

@Injectable()
export class TaskEffects {

  getTasks$ = createEffect(() => this.actions$.pipe(
    ofType<GetTasks>(ETaskActions.GetTasksAction),
    switchMap(action => {
      return this.taskService.getTasksOfSubtype(action.payload);
    }),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoading(RESET_LOADING_BODY));
      return of(new GetTasksOfStageSuccess(response.data));
    })
  ));

  deleteTask$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTask>(ETaskActions.DeleteTaskAction),
    switchMap(payload => this.taskService.delete(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se eliminó la tarea correctamente`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      this.store.dispatch(new RouterBack());
      return of(new DeleteTaskSuccess(response.data));
    })
  ));

  updateTask$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTask>(ETaskActions.UpdateTaskAction),
    switchMap(payload => this.taskService.updateTask(payload.id, payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se actualizó la tarea correctamente`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      this.store.dispatch(new RouterBack());
      return of(new UpdateTaskSuccess(response.data));
    })
  ));

  createTask$ = createEffect(() => this.actions$.pipe(
    ofType<CreateTask>(ETaskActions.CreateTaskAction),
    switchMap(payload => this.taskService.createTask(payload.payload)),
    switchMap((response: IResponse) => {
      this.store.dispatch(new GetLoadingSuccess(RESET_LOADING_BODY));
      this.notification.open(`¡Éxito! Se creo la tarea correctamente`,
        'cerrar', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: 'tracking-toast-bar-success'
      });
      this.store.dispatch(new RouterBack());
      return of(new CreateTaskSuccess(response.data));
    })
  ));

  constructor(
    private taskService: TaskService,
    private actions$: Actions,
    private notification: MatSnackBar,
    private store: Store<IAppState>
  ) { }
}
