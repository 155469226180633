import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../shared/models/other/http/Response.interface';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { IStage } from '../../shared/models/stage/Stage.interface';

@Injectable({
  providedIn: 'root'
})
export class StageService {

  constructor(private http: HttpClient) { }

  getStageById(stageId: string): Observable<IResponse> {
    return this.http.get<IResponse>(`${environment.apiEndPoint}/stages/${stageId}`);
  }

  getStagesBySubtypeId(subtypeId: string): Observable<IResponse> {
    return this.http.get<IResponse>(`${environment.apiEndPoint}/stages/subtype/${subtypeId}`);
  }

  reorderStages(stages: IStage[]): Observable<IResponse> {
    return this.http.post<IResponse>(`${environment.apiEndPoint}/stages/position`, stages);
  }

  updateStageNotification(stage: IStage): Observable<IResponse> {
    return this.http.patch<IResponse>(`${environment.apiEndPoint}/stages/${stage.codStage}/notify`, {
      notify: stage.notify,
    });
  }

  saveStage(stage: IStage): Observable<IResponse> {
    return this.http.post<IResponse>(`${environment.apiEndPoint}/stages`, stage);
  }

  updateStage(stage: IStage): Observable<IResponse> {
    return this.http.put<IResponse>(`${environment.apiEndPoint}/stages`, stage);
  }

  deleteStage(stage: IStage): Observable<IResponse> {
    return this.http.delete<IResponse>(`${environment.apiEndPoint}/stages/${stage.codStage}`);
  }

}
